import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { LARGE_SPACING, THEME_MAIN_COLOR } from '../theme';
import { FeatherIcon } from './FeatherIcon';

export const FileUpload = ({
  setSrc,
  inputFile,
}: {
  setSrc: (src: any) => void;
  inputFile: any;
}) => {
  const [dragActive, setDragActive] = React.useState(false);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const onUploadButtonClick = () => {
    setDragActive(false);
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileReader = new FileReader();
      const file = e.dataTransfer.files;
      if (file != null && file[0] != null) {
        fileReader.readAsDataURL(file[0]);
        fileReader.onload = (onLoadEvent) => {
          setSrc(onLoadEvent?.target?.result as string);
        };
      }
    }
  };
  const intl = useIntl();
  return (
    <Button
      onClick={onUploadButtonClick}
      onDrop={onDrop}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      style={{
        backgroundColor: dragActive ? '#C7C7C7' : 'white',
        border: '1px dashed #C7C7C7',
        textTransform: 'none',
      }}
      sx={{ p: LARGE_SPACING }}
    >
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <FeatherIcon name="upload" color={THEME_MAIN_COLOR} />
        </Box>
        <Stack
          style={{
            textAlign: 'center',
          }}
        >
          <Box>
            <Typography variant="caption">
              {intl.formatMessage({ id: 'form.dADPicture' })}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="caption"
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              {intl.formatMessage({ id: 'form.choosePicture' })}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Button>
  );
};
