import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { getGroups, Group, studentGetGroups } from '../../api/groupapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import {
  LARGE_SPACING,
  MEDIUM_SPACING,
  SMALL_SPACING,
  XXL_SPACING,
} from '../../theme';

interface StudentGroup {
  name: string;
  subjects: string[];
  groupId?: string;
  teacherName?: string;
}

type StudentOrTeacherGroup = StudentGroup | Group;

const GroupsList = ({ groups }: { groups: StudentOrTeacherGroup[] }) => (
  <Box>
    {groups.map((group) => (
      <Box
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: '6px',
          background: '#F6F6F6 0% 0% no-repeat padding-box',
          display: 'inline-block',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '80%',
        }}
        sx={{ px: SMALL_SPACING, m: SMALL_SPACING }}
        key={group.groupId}
      >
        <Typography
          variant="caption"
          style={{
            color: '#172B4D',
          }}
        >
          {group.name}
        </Typography>
      </Box>
    ))}
  </Box>
);

interface Props {
  isTeacher: boolean;
}
export const ProfileGroups = ({ isTeacher }: Props) => {
  const groupsResponse = useQuery({
    queryKey: ['groupspagegroupapi'],
    queryFn: isTeacher ? getGroups : studentGetGroups,
    ...QUERY_CONFIG,
  });
  if (!groupsResponse.data) {
    return <CircularProgress />;
  }
  // @ts-ignore
  const groups: StudentOrTeacherGroup[] = groupsResponse?.data?.length
    ? groupsResponse.data
    : [];
  const intl = useIntl();
  return (
    <Box sx={{ px: XXL_SPACING }}>
      <Box sx={{ px: MEDIUM_SPACING }}>
        <Typography variant="body1" sx={{ mb: LARGE_SPACING }}>
          {intl.formatMessage({ id: 'routes.groups' })}
        </Typography>
        {groups.length > 0 ? (
          <GroupsList groups={groups} />
        ) : (
          <Typography sx={{ pb: 2 }} variant="caption">
            No groups
          </Typography>
        )}
      </Box>
    </Box>
  );
};
