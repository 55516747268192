import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { ActivityDetails } from './ActivityDetails';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  MEDIUM_FONT_WEIGHT,
  MEDIUM_SPACING,
  XXL_SPACING,
} from '../../../theme';
import * as linksApi from '../../../api/linksapi';
import { LinkItem, SubjectItem } from '../../ops/components/types/types';
import { ContentProgress } from '../types';
import { formatLink } from '../../../utils/formatLink';
import { SelectableObjective } from './SelectableObjective';
import {
  createLinksApiQueryKey,
  deleteLinkImage,
  getLinkImageUploadUrl,
  LINKS_API_KEY,
} from '../../../api/linksapi';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { LESSON_PLAN_ACITIVTY_EDITOR as Z_INDEX } from '../../../utils/z-index';
import { uploadImageToS3 } from '../../../utils/uploadImageToS3';
import { QUERY_CONFIG } from '../../../utils/queryconfig';

export const ActivityEditor = ({
  activity,
  grade,
  subject,
  allTasks,
  editingOver,
  preDefinedBulletpoint,
  disablePortalForBookSelector = false,
}: {
  activity?: LinkItem;
  grade: string;
  subject: SubjectItem;
  allTasks: ContentProgress[];
  editingOver: (activity?: LinkItem) => void;
  preDefinedBulletpoint?: string;
  disablePortalForBookSelector?: boolean;
}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const createNotification = (translationId: string, success = true) => {
    enqueueSnackbar(intl.formatMessage({ id: translationId }), {
      variant: success ? 'success' : 'error',
    });
  };
  const [selectedBulletpointIds, setSelectedBulletpointIds] = useState<
    string[]
  >(activity?.bulletpointIds ?? []);
  const [imageBufferToBeUploaded, setImageBufferToBeUploaded] = React.useState<
    ArrayBuffer | undefined
  >(undefined);
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(activity?.imageUrl);
  const [linkTitle, setLinkTitle] = React.useState(activity?.title ?? '');
  const [link, setLink] = React.useState(activity?.link ?? '');
  const [page, setPage] = React.useState(activity?.page || '');
  const [bookId, setBookId] = React.useState(activity?.bookId || '');
  const [bookTitle, setBookTitle] = React.useState(activity?.bookTitle || '');
  const [desc, setDesc] = React.useState(activity?.description ?? '');
  const [privateLink, setPrivateLink] = React.useState(
    activity?.private !== undefined ? activity.private : true
  );
  const [activityType, setActivityType] = React.useState(
    activity?.activityType ?? 'FUNCTIONAL'
  );
  const { refetch } = useQuery({
    queryKey: [`${LINKS_API_KEY}/image`, activity?.linkId],
    queryFn: () => getLinkImageUploadUrl(activity?.linkId),
    ...QUERY_CONFIG,
    enabled: false,
  });
  const { mutate: createLink } = useMutation(linksApi.createAndAttachLink, {
    onSuccess: async (data) => {
      if (imageBufferToBeUploaded) {
        const putUrlResponse = await getLinkImageUploadUrl(data.created.linkId);
        await uploadImageToS3(
          putUrlResponse.url,
          imageBufferToBeUploaded,
          'image/jpeg'
        );
      }
      await queryClient.invalidateQueries([
        createLinksApiQueryKey(subject.code, grade),
      ]);
      createNotification('notifications.activity.created', true);
      setSelectedBulletpointIds([]);
      setLinkTitle('');
      setLink('');
      setPage('');
      setBookId('');
      setBookTitle('');
      setDesc('');
      setPrivateLink(false);
      setActivityType('LINK');
      setLoading(false);
      editingOver(data.created);
    },
    onError: () => {
      setLoading(false);
      createNotification('notifications.genericError', false);
    },
  });
  const { mutate: updateLink } = useMutation(linksApi.updateLink, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        createLinksApiQueryKey(subject.code, grade),
      ]);
      createNotification('notifications.activity.modified', true);
      setSelectedBulletpointIds([]);
      setLinkTitle('');
      setLink('');
      setPage('');
      setBookId('');
      setBookTitle('');
      setDesc('');
      setPrivateLink(false);
      setActivityType('LINK');
      setLoading(false);
      editingOver({ ...activity, ...variables });
    },
    onError: () => {
      setLoading(false);
      createNotification('notifications.genericError', false);
    },
  });

  const deleteImageMutation = useMutation(deleteLinkImage, {});

  // @ts-ignore
  // @ts-ignore
  return (
    <Box
      sx={{
        mt: XXL_SPACING,
        background: '#F1F4F6 0% 0% no-repeat padding-box',
        border: '1px solid #245F73',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '14px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          backgroundColor: '#245F73',
          borderRadius: '13px 13px 0px 0px',
          height: '100px',
        }}
        sx={{ p: LARGE_SPACING }}
      >
        <FeatherIcon
          size={IconSize['SMALL-MEDIUM']}
          name="loader"
          color="white"
        />
        <Typography
          variant="body1"
          style={{ fontWeight: MEDIUM_FONT_WEIGHT, color: 'white' }}
        >
          {intl.formatMessage({
            id: activity ? 'editActivity.header' : 'addActivity.header',
          })}
        </Typography>
      </Box>
      <Box sx={{ zIndex: Z_INDEX, marginTop: '-50px' }}>
        <Stack>
          <ActivityDetails
            deleteImage={async (callbackFn, linkIdToDelete) => {
              if (linkIdToDelete) {
                setLoading(true);
                await deleteImageMutation.mutateAsync(linkIdToDelete);
                setLoading(false);
              } else {
                setImageUrl(undefined);
                setImageBufferToBeUploaded(undefined);
              }
              callbackFn();
            }}
            uploadActivityImage={async (
              buffer: any,
              callbackFn: () => void,
              errorCallbackFn: () => void,
              linkIdToUpload?: string
            ) => {
              if (linkIdToUpload) {
                setLoading(true);
                const putUrlResponse = (await refetch()) as any;
                uploadImageToS3(putUrlResponse.data.url, buffer, 'image/jpeg')
                  .then(() => {
                    enqueueSnackbar('Image saved successfully', {
                      variant: 'success',
                    });
                    setLoading(false);
                    callbackFn();
                  })
                  .catch(() => {
                    enqueueSnackbar('Something went wrong when saving image', {
                      variant: 'error',
                    });
                    setLoading(false);
                    errorCallbackFn();
                  });
              } else {
                setImageBufferToBeUploaded(buffer);
                const base64String = btoa(
                  new Uint8Array(buffer).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                  )
                );
                setImageUrl(`data:image/jpeg;base64,${base64String}`);
                callbackFn();
              }
            }}
            disablePortalForBookSelector={disablePortalForBookSelector}
            imageUrl={imageUrl}
            setActivityType={(aT: string) => setActivityType(aT)}
            activityType={activityType as string}
            privateLink={privateLink}
            setPrivateLink={(pl) => setPrivateLink(pl)}
            setLinkTitle={(lT) => setLinkTitle(lT)}
            title={linkTitle}
            grade={grade}
            subject={subject.code}
            selectedBookId={bookId}
            setBookId={(bkId) => setBookId(bkId)}
            setBookTitle={(bT) => setBookTitle(bT)}
            setDesc={(d) => setDesc(d)}
            desc={desc}
            setLink={(l) => setLink(l)}
            link={link}
            setPage={(p) => setPage(p)}
            page={page}
            linkId={activity?.linkId}
          >
            {allTasks?.length > 0 && (
              <Box
                sx={{
                  mx: LARGE_SPACING,
                  mb: LARGE_SPACING,
                  mt: MEDIUM_SPACING,
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: BOLD_FONT_WEIGHT }}
                    sx={{ mb: MEDIUM_SPACING }}
                  >
                    {intl.formatMessage({
                      id: 'activityEditor.header.linkCurriculum',
                    })}{' '}
                    ({selectedBulletpointIds?.length ?? 0})
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  {allTasks.map((task) => {
                    const isChecked = selectedBulletpointIds.includes(
                      task.bulletpointId
                    );
                    return (
                      <SelectableObjective
                        key={task.bulletpointId}
                        task={task}
                        subject={subject}
                        grade={grade}
                        isChecked={isChecked}
                        setChecked={() =>
                          setSelectedBulletpointIds((previousVal) =>
                            isChecked
                              ? previousVal.filter(
                                  (pv) => pv !== task.bulletpointId
                                )
                              : [...previousVal, task.bulletpointId]
                          )
                        }
                      />
                    );
                  })}
                </Grid>
              </Box>
            )}
          </ActivityDetails>
          <Box
            style={{
              borderTop: '1px solid #AFBBC4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            sx={{
              mt: LARGE_SPACING,
              mb: LARGE_SPACING,
            }}
          >
            <Button
              style={{ height: '36px', borderRadius: '30px' }}
              onClick={() => editingOver(activity)}
              sx={{ mr: XXL_SPACING, mt: LARGE_SPACING }}
              variant="outlined"
              disabled={loading}
            >
              {intl.formatMessage({ id: 'button.cancel' })}
            </Button>
            <Button
              style={{ height: '36px', borderRadius: '30px' }}
              sx={{ mr: XXL_SPACING, mt: LARGE_SPACING }}
              variant="contained"
              disabled={
                (preDefinedBulletpoint === undefined &&
                  selectedBulletpointIds.length === 0) ||
                !desc ||
                (activityType === 'BOOK' && !bookId) ||
                loading
              }
              onClick={() => {
                setLoading(true);
                if (activity) {
                  updateLink({
                    activityType,
                    subjectId: subject.code,
                    gradeId: grade,
                    linkId: activity.linkId,
                    description: desc,
                    link: formatLink(link),
                    title: linkTitle,
                    bulletpointIds: selectedBulletpointIds,
                    type: 'WEB',
                    private: privateLink,
                    enabled: true,
                    page,
                    bookTitle,
                    bookId,
                  });
                } else {
                  createLink({
                    activityType,
                    linkId: 0,
                    description: desc,
                    link: formatLink(link),
                    title: linkTitle,
                    bulletpointIds:
                      selectedBulletpointIds?.length > 0
                        ? selectedBulletpointIds
                        : [preDefinedBulletpoint],
                    type: 'WEB',
                    private: privateLink,
                    enabled: true,
                    page,
                    bookTitle,
                    bookId,
                    subjectId: subject.code,
                    gradeId: grade,
                  });
                }
              }}
            >
              {!loading && intl.formatMessage({ id: 'button.save' })}
              {loading && <CircularProgress size={30} />}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
