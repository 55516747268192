import {
  bool,
  number,
  object,
  or,
  string,
  stringLiterals,
} from '@recoiljs/refine';
import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';

export const YEAR_PLAN_PAGE_STORE_KEY = 'yearPlanPage';

export interface ContentViewState {
  grade: string;
  subject: string;
  groupName: string;
  groupId: string;
  amountOfLessons: number;
  lessonNumber: number;
  year: number;
}

export const isContentViewState = (
  contentViewState: ContentViewState | {}
): contentViewState is ContentViewState =>
  (contentViewState as ContentViewState).grade !== undefined &&
  (contentViewState as ContentViewState).subject !== undefined &&
  (contentViewState as ContentViewState).groupId !== undefined;

// using {} instead of undefined because recoil-sync flips when you have empty object in url
export const contentState = atom<ContentViewState | {}>({
  key: 'contentState',
  default: {},
  effects: [
    syncEffect({
      refine: or(
        object({
          grade: string(),
          subject: string(),
          groupName: string(),
          groupId: string(),
          amountOfLessons: number(),
          lessonNumber: number(),
          year: number(),
        }),
        object({})
      ),
      itemKey: 'content',
      storeKey: YEAR_PLAN_PAGE_STORE_KEY,
    }),
  ],
});

export const yearPlanDialogActiveTab = atom<'Content' | 'Lesson'>({
  key: 'yearPlanDialogActiveTab',
  default: 'Content',
  effects: [
    syncEffect({
      refine: stringLiterals({ Content: 'Content', Lesson: 'Lesson' }),
      itemKey: 'dialogActiveTab',
      storeKey: YEAR_PLAN_PAGE_STORE_KEY,
    }),
  ],
});
export const contentContentDialogOpen = atom<boolean>({
  key: 'contentContentDialogOpen',
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'dialogOpen',
      storeKey: YEAR_PLAN_PAGE_STORE_KEY,
    }),
  ],
});

export const contentLoadingState = atom<boolean>({
  key: 'contentLoadingState',
  default: false,
});

export const contentLeftPanelOpenState = atom<boolean>({
  key: 'contentLeftPanelOpenState',
  default: true,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'leftPanelOpen',
      storeKey: YEAR_PLAN_PAGE_STORE_KEY,
    }),
  ],
});

export const contentContentSelectorObjectiveMode = atom<boolean>({
  key: 'contentContentSelectorObjectiveMode',
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'objectiveMode',
      storeKey: YEAR_PLAN_PAGE_STORE_KEY,
    }),
  ],
});

export const contentRedirectAfterSaveUrl = atom<string | undefined>({
  key: 'contentRedirectAfterSaveUrl',
  default: undefined,
});
