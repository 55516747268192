import React from 'react';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useRecoilState } from 'recoil';
import { parseSubjectList } from '../../../../utils/ops/utils';
import { getSubjectsByGrade } from '../../../../utils/ops/getSubjectsByGrade';
import { ContentSelector } from '../../../my-plan/year-plan/ContentSelector';
import { useOps } from '../../../../hooks/useOps';
import {
  curriculumContentSelectorObjectiveMode,
  curriculumLinksState,
  curriculumSubjectsPageSelectedContentIds,
  curriculumSubjectsPageSelectedObjectiveIds,
} from '../../../../App/state/curriculum.state';

export const ReadObjective = ({
  subjectCode,
  grade,
}: {
  subjectCode: string;
  grade: number;
}) => {
  const [objectiveMode, setObjectiveMode] = useRecoilState(
    curriculumContentSelectorObjectiveMode
  );
  const { ops } = useOps();
  const md = useMediaQuery(useTheme().breakpoints.down('md'));
  const [_, setLinksState] = useRecoilState(curriculumLinksState);
  const [selectedContentIds, setSelectedContentIds] = useRecoilState(
    curriculumSubjectsPageSelectedContentIds
  );
  const [selectedObjectiveIds, setSelectedObjectiveIds] = useRecoilState(
    curriculumSubjectsPageSelectedObjectiveIds
  );

  if (!ops) {
    return <CircularProgress />;
  }

  if (!grade) {
    return <div> No grade selected </div>;
  }

  const rawSubjects = parseSubjectList(ops, { onlyFavorites: false }, []);
  const subject = getSubjectsByGrade(rawSubjects, grade).find(
    (it) => it.code === subjectCode
  );

  if (!subject) {
    return (
      <div>
        No objectives found for grade: {grade} and subject code: {subjectCode}
      </div>
    );
  }
  return (
    <Box style={{ display: 'flex' }}>
      {subject?.grades && (
        <ContentSelector
          objectiveMode={objectiveMode}
          setObjectiveMode={setObjectiveMode}
          gradeItem={subject.grades.find((g) => g.grade === grade)!!}
          selectedContentIds={selectedContentIds as string[]}
          setSelectedContentIds={setSelectedContentIds}
          selectedObjectiveIds={selectedObjectiveIds as string[]}
          setSelectedObjectiveIds={setSelectedObjectiveIds}
          smallMode={md}
          openLinksForBulletpoint={(bulletpointId: string) => {
            setLinksState(bulletpointId);
          }}
          subjectCode={subjectCode}
        />
      )}
    </Box>
  );
};
