import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  LARGE_SPACING,
  LESS_LIGHT_BORDER,
  MEDIUM_FONT_WEIGHT,
  THEME_MAIN_TEXT_COLOR,
  XXL_SPACING,
} from '../../../theme';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { CancelSave } from './CancelSave';

export const NoteEditor = ({
  close,
  save,
  existingName,
  existingDescription,
  existingShowToStudents,
}: {
  close: () => void;
  save: (description: string, showToStudents: boolean, name?: string) => void;
  existingName?: string;
  existingDescription?: string;
  existingShowToStudents?: boolean;
}) => {
  const intl = useIntl();
  const [name, setName] = useState<string | undefined>(existingName);
  const [showToStudents, setShowToStudents] = useState(
    existingShowToStudents ?? false
  );
  const [description, setDescription] = useState(existingDescription ?? '');

  return (
    <Box
      sx={{
        mt: XXL_SPACING,
        backgroundColor: 'rgb(247, 248, 250)',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '10px',
        }}
        sx={{ p: LARGE_SPACING, borderBottom: LESS_LIGHT_BORDER }}
      >
        <FeatherIcon
          size={IconSize['SMALL-MEDIUM']}
          name="clipboard"
          color={THEME_MAIN_TEXT_COLOR}
        />
        <Typography variant="body1" style={{ fontWeight: MEDIUM_FONT_WEIGHT }}>
          {intl.formatMessage({
            id: existingDescription
              ? 'noteEditor.editHeader'
              : 'noteEditor.header',
          })}
        </Typography>
      </Box>
      <Stack sx={{ p: LARGE_SPACING }}>
        <Box>
          <Typography
            variant="body1"
            style={{ fontWeight: MEDIUM_FONT_WEIGHT }}
          >
            {intl.formatMessage({ id: 'noteEditor.secondHeader' })}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          sx={{ mt: LARGE_SPACING }}
        >
          <TextField
            label={
              <Typography variant="caption">
                {intl.formatMessage({ id: 'noteEditor.name' })}
              </Typography>
            }
            variant="outlined"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            style={{ minWidth: '40%' }}
          />
          <FormControlLabel
            labelPlacement="start"
            style={{ maxWidth: '40%' }}
            control={
              <Switch
                checked={!!showToStudents}
                onChange={() => setShowToStudents((currVal) => !currVal)}
              />
            }
            label={
              <Typography variant="caption">
                {intl.formatMessage({ id: 'noteEditor.showToStudents' })}
              </Typography>
            }
          />
        </Box>
        <Box sx={{ mt: LARGE_SPACING }}>
          <TextField
            multiline={true}
            minRows={2}
            maxRows={3}
            label={
              <Typography variant="caption">
                {intl.formatMessage({ id: 'noteEditor.description' })}
              </Typography>
            }
            variant="outlined"
            onChange={(evt) => setDescription(evt.target.value)}
            value={description}
            fullWidth
            inputProps={{ maxLength: 2048 }}
          />
        </Box>
        <Box sx={{ mt: LARGE_SPACING }}>
          <CancelSave
            cancelLabel={intl.formatMessage({ id: 'button.cancel' })}
            saveLabel={intl.formatMessage({ id: 'button.save' })}
            cancel={() => close()}
            save={() => save(description, showToStudents, name)}
            saveDisabled={description?.length < 1}
          />
        </Box>
      </Stack>
    </Box>
  );
};
