import React, { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Stack, Typography, Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useBreakpoints } from '../hooks/useBreakpoints';
// @ts-ignore
import PolarLogoImage from './polarlogo.svg';
// @ts-ignore
import PolarLogoMobileImage from './polarlogomobile.svg';
import { useAuth } from '../utils/auth/useAuth';
import {
  BOLD_FONT_WEIGHT,
  DETAIL_COLOR,
  LARGE_SPACING,
  LEFT_NAV_BACKGROUND_COLOR,
  THEME_MAIN_COLOR,
  XXL_SPACING,
} from '../theme';
import { FeatherIcon } from '../components/FeatherIcon';
import { contentRedirectAfterSaveUrl } from '../App/state/year-plan.state';

interface Route {
  path: string | (() => void);
  icon: ReactElement<any, any>;
  routeName: string;
  student: boolean;
  isLoggedIn: boolean;
  isActive: boolean;
  visible: boolean;
}

export const getMainNavWidth = (isMobile: boolean) =>
  isMobile ? '40vw' : '114px';

export const MainNav = () => {
  const { user, isLoggedIn, logout } = useAuth();
  const [____, setRedirectAfterSaveUrl] = useRecoilState(
    contentRedirectAfterSaveUrl
  );
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const navigateTo = (event: any, url: string): void => {
    event.preventDefault();
    event.stopPropagation();
    navigate(url);
  };
  const { useHamburgerNavigation } = useBreakpoints();
  const menuElementWidth = getMainNavWidth(useHamburgerNavigation);
  const isDev = window.location.href.includes('lvh.me');
  const routes: Route[] = [
    {
      path: '/home',
      icon: <FeatherIcon name="calendar" />,
      routeName: intl.formatMessage({ id: 'routes.home' }),
      student: true,
      isLoggedIn: true,
      isActive: location.pathname.includes('home'),
      visible: true,
    },
    {
      path: '/curriculum/subjects',
      icon: <FeatherIcon name="award" />,
      routeName: intl.formatMessage({ id: 'routes.curriculum' }),
      student: false,
      isLoggedIn: true,
      isActive: location.pathname.includes('curriculum'),
      visible: true,
    },
    {
      path: '/my-plan/year-plan',
      icon: <FeatherIcon name="edit" />,
      routeName: intl.formatMessage({ id: 'routes.myplan' }),
      student: false,
      isLoggedIn: true,
      isActive: location.pathname.includes('my-plan'),
      visible: true,
    },
    {
      path: '/groups',
      icon: <FeatherIcon name="users" />,
      routeName: intl.formatMessage({ id: 'routes.groups' }),
      student: false,
      isLoggedIn: true,
      isActive: location.pathname.includes('groups'),
      visible: isDev,
    },
    {
      path: user?.role === 'TEACHER' ? '/progress' : '/student/progress',
      icon: <FeatherIcon name="trending-up" />,
      routeName: intl.formatMessage({ id: 'routes.progress' }),
      student: true,
      isLoggedIn: true,
      isActive: location.pathname.includes('progress'),
      visible: isDev,
    },
    {
      path: '/login',
      icon: <FeatherIcon name="log-in" />,
      routeName: intl.formatMessage({ id: 'routes.login' }),
      student: true,
      isLoggedIn: false,
      isActive: location.pathname.includes('login'),
      visible: true,
    },
    {
      path: () => logout(),
      icon: <FeatherIcon name="log-out" />,
      routeName: intl.formatMessage({ id: 'routes.logout' }),
      student: true,
      isLoggedIn: true,
      isActive: false,
      visible: true,
    },
  ].filter(({ visible }) => visible);

  return (
    <Box style={{ backgroundColor: LEFT_NAV_BACKGROUND_COLOR }}>
      <Box sx={{ my: LARGE_SPACING }}>
        <img
          alt="Polar Partners logo"
          style={{
            width: menuElementWidth,
            height: useHamburgerNavigation ? '33px' : 'auto',
          }}
          src={useHamburgerNavigation ? PolarLogoMobileImage : PolarLogoImage}
        />
      </Box>
      {routes
        .filter((ro) =>
          isLoggedIn
            ? (user?.role === 'TEACHER' || ro.student) && ro.isLoggedIn
            : !ro.isLoggedIn
        )
        .map((route, i, arr) => (
          <Button
            variant="text"
            onClick={(e: any) => {
              setRedirectAfterSaveUrl(undefined);
              if (typeof route.path === 'string') {
                navigateTo(e, route.path);
              } else {
                route.path();
              }
            }}
            key={route.path + route.routeName}
            sx={{
              color: THEME_MAIN_COLOR,
              mt: i + 1 === arr.length ? 'auto' : 0,
              mx: 0,
              px: 0,
              py: XXL_SPACING,
            }}
            style={{
              display: 'block',
              textTransform: 'capitalize',
              backgroundColor: route.isActive
                ? 'white'
                : LEFT_NAV_BACKGROUND_COLOR,
              boxShadow: route.isActive
                ? `5px 0px 0px ${DETAIL_COLOR} inset`
                : '',
              borderRadius: '0px',
              width: menuElementWidth,
            }}
          >
            <Stack
              direction="row"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: menuElementWidth,
              }}
            >
              {!useHamburgerNavigation ? (
                <>{route.icon}</>
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  width="50%"
                  spacing={XXL_SPACING}
                >
                  <Box flexGrow={0}>{route.icon}</Box>
                  <Box flexGrow={1} style={{ textAlign: 'left' }}>
                    {route.routeName}
                  </Box>
                </Stack>
              )}
            </Stack>
            {!useHamburgerNavigation && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: menuElementWidth,
                }}
              >
                <Typography
                  style={{
                    display: 'block',
                    fontWeight: route.isActive ? BOLD_FONT_WEIGHT : '',
                  }}
                  variant="caption"
                >
                  {route.routeName}
                </Typography>
              </Box>
            )}
          </Button>
        ))}
    </Box>
  );
};
