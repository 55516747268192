import { Typography } from '@mui/material';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useRecoilState } from 'recoil';
import { groupEditorTimetableSubjectWidth } from '../../../App/state/group.state';
import { SUBJECT_BOX_HEIGHT } from './consts';

export const DraggableFavoriteSubject = ({
  favoriteSubject,
}: {
  favoriteSubject: string;
}) => {
  const [boxWidth, _] = useRecoilState(groupEditorTimetableSubjectWidth);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'subject',
    item: { id: favoriteSubject },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        display: 'inline-block',
        height: SUBJECT_BOX_HEIGHT,
        padding: '1px',
        borderRadius: '8px',
        border: '1px solid',
        margin: '4px',
        overflow: 'hidden',
        width: `${boxWidth}px`,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Typography variant="body2">
          {favoriteSubject.split('-').slice(0, -1).join('-')}
        </Typography>
      </div>
    </div>
  );
};
