export const messagesEN = {
  'application.name': 'Polarhub',
  'application.title': 'Polahub',

  'navigation.register.title': 'Sign up',
  'navigation.login.title': 'Login',
  'navigation.users.title': 'Users',

  'dialog.submit.title.save': 'Save',
  'dialog.confirm.title': 'Confirm',
  'dialog.confirm.description': 'Are you sure?',
  'dialog.button.ok': 'Ok',
  'dialog.button.cancel': 'Cancel',

  'search.startTime': 'From',
  'search.endTime': 'To',

  'footer.itemCount': 'Results',

  'dashboardPage.table.title': "What's new",

  'alert.title.error': 'Error',
  'alert.title.success': 'Success',
  'alert.message.fetch': 'Listing',
  'alert.message.search': 'Search',
  'alert.message.create': 'Save',
  'alert.message.update': 'Update',
  'alert.message.delete': 'Delete',

  'form.add': 'Add',
  'form.delete': 'Delete',
  'form.submit': 'Submit',
  'form.save': 'Save',
  'form.cancel': 'Cancel',
  'form.login': 'Login',
  'form.logout': 'Logout',
  'form.showActivityToOthers': 'Show activity to other users',
  'form.addActivityDetails': 'Add activity details',
  'form.title': 'otsikko',
  'form.desc': 'Description',
  'form.pageOrChapter': 'Page or chapter',
  'form.webAddress': 'Web address',
  'form.dADPicture': 'Drag and drop a picture or',
  'form.choosePicture': 'choose from my computer',

  'value.unknown': 'Unknown',
  'value.active.true': 'Active',
  'value.active.false': 'Inactive',
  'value.private.true': 'Private',
  'value.private.false': 'Public',

  'registerPage.table.title': 'Sign up',
  'registerPage.table.username': 'Username',
  'registerPage.table.password': 'Password',
  'registerPage.table.firstname': 'First name',
  'registerPage.table.lastname': 'Last name',
  'registerPage.link.signin': 'Signin',

  'registerPage.form.username': 'Email address',

  'loginPage.table.title': 'Login',
  'loginPage.table.username': 'Username',
  'loginPage.table.password': 'Password',
  'loginPage.link.signup': 'Sign up',
  'loginPage.snackbar.message.failed': 'Login failed',

  'opsPage.dialog.title': 'OPS',
  'opsPage.table.title': 'OPS',
  'opsPage.ops.subjects': 'Subjects',
  'opsPage.ops.grades': 'Grades',
  'opsPage.ops.objectives': 'Objectives',
  'opsPage.ops.transversals': 'Transversals',
  'opsPage.ops.contents': 'Contents',
  'opsPage.filter.onlyFavorites': 'Only favorites',
  'opsPage.filter.onlyGrades': 'Only grades',
  'opsPage.ops.lessonTopic': 'Lesson topic',
  'opsPage.ops.content': 'Content',
  'opsPage.ops.lessonPlan': 'Lesson Plan',
  'opsPage.ops.lessons': 'lessons',

  'opsPage.dialog.form.field.title': 'Title',
  'opsPage.dialog.form.field.type': 'Type',
  'opsPage.dialog.form.field.link': 'Link',
  'opsPage.dialog.form.field.private': 'Private',
  'opsPage.dialog.form.field.description': 'Description',

  'opsPage.form.date': 'Date',

  'usersPage.dialog.title': 'User details',
  'usersPage.table.title': 'Users',
  'usersPage.table.header.username': 'Username',
  'usersPage.table.header.description': 'Description',
  'usersPage.table.header.password': 'Password',
  'usersPage.table.header.password_confirmation': 'Confirm password',
  'usersPage.table.header.firstname': 'First name',
  'usersPage.table.header.lastname': 'Last name',
  'usersPage.table.header.email': 'Email',
  'usersPage.table.header.status': 'Status',
  'usersPage.table.header.lastloginAt': 'Last login',
  'usersPage.table.header.createdAt': 'Registered',
  'usersPage.table.header.yourGroups': 'Your groups',

  'subject.subject': 'Subject',
  'subject.grade': 'Grade',
  'subject.startTime': 'Start',
  'subject.endTime': 'End',

  'snackBar.topicName.failed': 'Topic name update failed',
  'snackBar.topicName.updated': 'Topic name updated',
  'snackBar.topicName.created': 'Topic name created',
  'snackBar.topicCreate.failed': 'Topic creation failed',
  'snackBar.topicCreate.created': 'Topic created',

  'timetable.startTime': 'Start',
  'timetable.endTime': 'End',
  'timetable.title': 'Timetable',

  'timetable.filters.clearAll': 'Clear All',

  'timetable.select.title': 'Create a new timetable',
  'timetable.select.titleExisting': 'Create a new timetable or select existing',
  'timetable.select.create': 'Create new',
  'timetable.select.existing': 'Choose existing timetable',

  'myplanpage.header.content': 'Content',
  'myplanpage.header.calendar': 'Calendar',

  'myplancalendarpage.skills': 'Skills to assign for this week',
  'myplancalendarpage.no-skills':
    'No skills selected. Select skills from content tab',

  'select-week': 'Select week',
  'select-day': 'Select day',

  'frontpage.tabs.week': 'Week',
  'frontpage.tabs.day': 'Day',

  'schedule.controls.weekend': 'Show weekend',
  'schedule.controls.today': 'TODAY',

  'schedule.controls.weekAbbrev': 'WK',

  'days.mon': 'mon',
  'days.tue': 'tue',
  'days.wed': 'wed',
  'days.thu': 'thu',
  'days.fri': 'fri',
  'days.sat': 'sat',
  'days.sun': 'sun',

  'curriculumpage.subjects': 'Subjects',
  'curriculumpage.pedagogy': 'Pedagogy',
  'curriculumpage.assessment': 'Assessment',
  'curriculumpage.support': 'Support',

  'curriculumsubjectspage.title': 'Curriculum',
  'curriculumsubjectspage.tabs.read': 'Read',
  'curriculumsubjectspage.tabs.select': 'Select',
  'curriculumsubjectspage.noSubject': 'No selected Subject',
  'curriculumsubjectspage.noSubjectSelect':
    'Select subject and grade from the menu',
  'curriculumsubjectspage.activitiesFor': 'Activities for',
  'curriculumsubjectspage.activity': 'Activity',
  'curriculumsubjectspage.noActivity': 'No activity',

  'button.delete': 'Delete',
  'button.save': 'Save',
  'button.edit': 'Edit',
  'button.cancel': 'Cancel',

  'button.confirmDelete.title': 'Are you sure you want to delete?',
  'button.confirmDelete.content': 'This action is permanent',

  'timetable.validator.isbefore':
    'Timetable end date should not be before start date',
  'timetable.validator.overlapping': 'has overlapping start or end date',
  'timetable.validator.tooshort': 'Timetable cant be shorter than 7 days',

  'timetable.editor.edit-week': 'Week',
  'timetable.editor.edit-timetable': 'Full',
  'websocket.profile.image.updated': 'Avatar created successfully',
  'websocket.profile.image.failed': 'Avatar creation failed',
  'groupPage.newTimetable': 'New timetable',
  'groups.student.dialog.error.existing':
    'Student has already been added to the group',
  'groups.student.dialog.error.email': 'Email is not valid',
  'myplan.topic.confirmation.new.title': 'Do you want to start a new topic?',
  'notifications.genericError': 'Something went wrong',
  'notifications.activity.created': 'Activity created',
  'notifications.activity.modified': 'Activity modified',
  'notifications.activity.removed': 'Activity removed',

  'tootips.lessonPlanProgress': 'The lesson plan is not completed!',
  'buttons.importGroups': 'Import groups',
  'buttons.addNewGroup': 'Add group',
  'buttons.addContent': 'Add content',
  'buttons.editLessonPlan': 'Edit lesson plan',
  'buttons.createLessonPlan': 'Add lesson plan',
  'buttons.showCurriculumContents': 'Show curriculum contents',
  'buttons.hideCurriculumContents': 'Hide curriculum contents',
  'importGroupsDialog.title': 'Import groups',
  'importGroupsDialog.content':
    'CSV should have fields: startDate, endDate, weekDay, time, length, groupName, grade, subject, teacher',
  'importGroupsDialog.uploadButton': 'Choose CSV',
  'importGroupsDialog.saveButton': 'Save',
  'importGroupsDialog.validationError': 'Error when processing CSV with value:',
  'importGroupsDialog.groupsFound':
    'Groups found: {groups}, Timetables found: {timetables}',
  'notifications.importGroupsDialog.importError':
    'Something went wrong when importing groups, please check results',
  'notifications.importGroupsDialog.importSuccess':
    'Groups were imported successfully',
  'button.done': 'Done',
  'groups.delete.confirm': 'Are you sure you want to delete this group?',
  'notifications.group.deleted': 'Group deleted successfully',
  'topicHeader.instruction':
    'Start creation of a lesson plan by choosing tasks from a subject',
  'topicHeader.instruction.externalTopic': 'Select tasks for topic',
  'topicContainer.addToLesson': 'Select lesson',
  'routes.home': 'Home',
  'routes.curriculum': 'Curriculum',
  'routes.myplan': 'Planning',
  'routes.groups': 'Groups',
  'routes.progress': 'Progress',
  'routes.login': 'Sign in',
  'routes.logout': 'Sign out',
  'topicContainer.noLessonPlan': 'No lesson plan',
  'lessonPanel.noCurriculumContents': 'No Curriculum Contents',

  'topicContainer.noLessonPlan.explanation':
    'Select add lesson plan button to define lesson plan',
  BOOK: 'Book activity:',
  FUNCTIONAL: 'Real life activity:',
  LINK: 'Link to visit:',
  'topicPanel.curriculumContent': 'Curriculum objectives',
  'schedule.buttons.nextWeek': 'Next week',
  'annualPlanPage.noPlan': 'No selected plan',
  'panelMobile.groupSubjectTitle': 'Groups and Subjects',
  'panelMobile.gradeSubjectTitle': 'Grade and Subject',
  'yearplan.contentmodal.contenttab': 'Content',
  'yearplan.contentmodal.lessonplantab': 'Lesson plan',
  'yearplan.contentmodal.noDate': 'Lesson in not scheduled',
  'yearplan.contentmodal.all': 'All',
  'topic.placeholderName': 'Lesson topic',

  'yearplan.contentmodal.contentAreasTab': 'Content areas',
  'yearplan.contentmodal.objectivesTab': 'Objectives',
  'activityEditor.header.linkCurriculum': 'Link curriculum content',
  'noteEditor.header': 'Add note',
  'noteEditor.editHeader': 'Edit note',
  'noteEditor.secondHeader': 'Note content',
  'noteEditor.name': 'Name of the note (optional)',
  'noteEditor.showToStudents': 'Show to students',
  'noteEditor.description': 'Type here...',
  'addActivity.header': 'Add activity',
  'editActivity.header': 'Edit activity',
  'noteOrActivity.removeFromLesson': 'Remove from lesson',
  'noteOrActivity.remove': 'Remove',
  'noteOrActivity.edit': 'Edit',
  'bookSelector.label': 'Select book',
  'bookSelector.addNewBook.prefix': 'Add',
  'profileEditor.header': 'Edit profile',
  'profileEditor.subHeader': 'Personal information',
  'profileMenu.changeLocale': 'Change locale',
  'profileMenu.editProfile': 'Edit profile',
  'lessonplan.timeline.header': 'Lesson timeline',
  'lessonplan.timeline.desc':
    'Plan the structure and activities for the lesson.',
  'lessonplan.timeline.initiation': 'Initiation',
  'lessonplan.timeline.practicing': 'Practicing',
  'lessonplan.timeline.closing': 'Closing',
  'lessonplan.timeline.homework': 'Homework',
  'lessonplan.activityLibrary.header.activity': 'Activity library',
};
