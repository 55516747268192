import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import { getGroups, Group, GROUPS_QUERY_KEY } from '../../../api/groupapi';
import {
  contentContentDialogOpen,
  contentLeftPanelOpenState,
  contentState,
  ContentViewState,
  isContentViewState,
} from '../../../App/state/year-plan.state';
import { CenterLoader } from '../../../components/CenterLoader';
import {
  ClearAllButton,
  FilterCategory,
} from '../../../components/FilterCategory';
import { PanelMobile } from '../../../components/PanelMobile';
import { PanelOrDrawer } from '../../../components/PanelOrDrawer';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { getSubjectsByGrade } from '../../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../../utils/ops/utils';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { SubjectItem } from '../../ops/components/types/types';
import { ContentPanelContentAndDialog } from './ContentPanelContentAndDialog';
import { SelectedGroupSubject } from './yearPlanUtils';
import { YearTimeLines } from './YearTimeLines';
import { useOps } from '../../../hooks/useOps';
import { PanelContentDivider } from '../../../components/PanelContentDivider';
import { useArrayState } from '../../../hooks/useArrayState';
import { ContentMissing } from '../../../components/ContentMissing';
import { useTimetables } from '../../../hooks/useTimetables';
import { subjectAndGradeToFavorite } from '../../groups/validateAndFormatGroup';
import useIsFirstRender from '../../../hooks/useIsFirstRender';
import { scrollToContentFn } from '../../home/util';

export const YearPlanPage = () => {
  const isFirstRender = useIsFirstRender();
  const [panelOpenState, setPanelOpenState] = useRecoilState(
    contentLeftPanelOpenState
  );
  const [contentViewState, setContentViewState] = useRecoilState(contentState);
  const [rightPanelOpenState, setRightPanelOpenState] = useState(true);

  const selectedGroups = useArrayState<string>(
    isContentViewState(contentViewState) ? [contentViewState.groupId] : []
  );
  const selectedSubjects = useArrayState<string>(
    isContentViewState(contentViewState) ? [contentViewState.subject] : []
  );

  const [contentDialogOpen, setContentDialogOpen] = useRecoilState(
    contentContentDialogOpen
  );
  if (isFirstRender && isContentViewState(contentViewState)) {
    // eslint-disable-next-line no-new
    new Promise(() =>
      setTimeout(
        () =>
          scrollToContentFn(
            contentViewState.lessonNumber,
            contentViewState.year,
            contentViewState.grade,
            contentViewState.subject
          ),
        2000
      )
    );
  }
  const intl = useIntl();
  React.useEffect(
    () => () => {
      setContentViewState({});
      setPanelOpenState(true);
      setContentDialogOpen(false);
    },
    []
  );
  React.useEffect(() => {
    if (contentViewState) {
      setRightPanelOpenState(true);
    }
  }, [contentViewState]);
  const { ops } = useOps();
  const groupsResponse = useQuery({
    queryKey: [GROUPS_QUERY_KEY],
    queryFn: getGroups,
    ...QUERY_CONFIG,
  });
  const groups = groupsResponse?.data as Group[];
  const { timetables } = useTimetables(selectedGroups.values);
  const subjects = useMemo(
    () =>
      ops
        ? getSubjectsByGrade(parseSubjectList(ops, { onlyFavorites: false }))
        : [],
    [ops]
  );
  const { subjectColors } = useSubjectColor(subjects?.map((s) => s.code));
  const calculatedGroupSubjects = useMemo<SelectedGroupSubject[]>(() => {
    const calcGroups = selectedGroups.values?.map(
      (groupId) => groups?.find((g) => g.groupId === groupId) as Group
    );
    const calcSubjects = selectedSubjects.values?.map(
      (subjectCode) =>
        subjects?.find((s) => s.code === subjectCode) as SubjectItem
    );

    return calcGroups
      ?.filter((g) => g !== undefined)
      .map((group) =>
        calcSubjects?.map(
          (subject): SelectedGroupSubject => ({
            group: {
              groupId: String(group?.groupId),
              name: group?.name,
            },
            subject: subject.code,
            year: 2023, // TODO: hard-coded for only dealing with year 2022 ops for now
            grade: Number(group.grade),
            isFavorite:
              (group?.grade &&
                group?.grade.length > 0 &&
                group?.favorites?.includes(
                  subjectAndGradeToFavorite(subject?.code, group?.grade)
                )) ||
              false,
          })
        )
      )
      .flatMap((a) => a);
  }, [selectedGroups, timetables, selectedSubjects, groups]);

  const selectableGroups = useMemo(
    () =>
      groups?.map((g) => ({
        id: String(g.groupId),
        label: g.name,
        active: selectedGroups.includes(String(g.groupId)),
        obscured: false,
      })),
    [groups, selectedGroups]
  );
  const selectableSubjects = useMemo(
    () =>
      subjects
        ?.filter((subject) =>
          groups?.some(
            (g) =>
              selectedGroups?.values.length > 0 &&
              g?.grade &&
              g?.favorites?.includes(
                subjectAndGradeToFavorite(subject?.code, g.grade)
              ) &&
              selectedGroups.includes(String(g.groupId))
          )
        )
        .map((s) => ({
          id: s.code,
          label: s.title,
          accent: subjectColors[s.code].primaryColor,
          active: selectedSubjects.includes(s.code),
          obscured: false,
        })),
    [subjects, selectedGroups, groups, selectedSubjects]
  );
  if (!ops || !groups) {
    return <CenterLoader />;
  }
  return (
    <Stack
      direction="row"
      style={{
        maxHeight: '100%',
      }}
    >
      {calculatedGroupSubjects.length === 0 && (
        <Stack
          direction="column"
          flexGrow={1}
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ContentMissing
            title={intl.formatMessage({ id: 'annualPlanPage.noPlan' })}
            description={intl.formatMessage({
              id: 'curriculumsubjectspage.noSubjectSelect',
            })}
          />
        </Stack>
      )}
      <PanelMobile
        title={intl.formatMessage({ id: 'panelMobile.groupSubjectTitle' })}
        isOpen={panelOpenState}
        onOpen={() => setPanelOpenState(true)}
        onClose={() => setPanelOpenState(false)}
      >
        <Box
          style={{
            float: 'right',
            marginRight: '1em',
          }}
        >
          <ClearAllButton
            onClick={() => {
              selectedGroups.clear();
              selectedSubjects.clear();
            }}
          />
        </Box>
        <FilterCategory
          title={intl.formatMessage({ id: 'routes.groups' })}
          options={selectableGroups}
          onToggle={(id) => {
            selectedGroups.toggle(id);
          }}
        />
        <PanelContentDivider />
        <FilterCategory
          title={intl.formatMessage({ id: 'opsPage.ops.subjects' })}
          options={selectableSubjects}
          onToggle={(s) => {
            selectedSubjects.toggle(s);
          }}
        />
      </PanelMobile>

      <YearTimeLines
        subjects={subjects}
        selectedGroupSubjects={calculatedGroupSubjects}
      />
      {isContentViewState(contentViewState) && (
        <PanelOrDrawer
          panelAlignment="right"
          panelOnly={contentDialogOpen} // dirty hack to fix visual bug on tablets before demo
          showPanelControls={true}
          openCallback={() => setRightPanelOpenState(true)}
          closeCallback={() => setRightPanelOpenState(false)}
          forceOpen={rightPanelOpenState}
        >
          {rightPanelOpenState && (
            <ContentPanelContentAndDialog
              content={contentViewState}
              setContent={(view: ContentViewState | {}) => {
                setContentViewState(view);
              }}
            />
          )}
        </PanelOrDrawer>
      )}
    </Stack>
  );
};
