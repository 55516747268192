import { array, bool, nullable, number, string } from '@recoiljs/refine';
import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';

export const CURRICULUM_PAGE_STORE_KEY = 'curriculumPage';

export const curriculumSubjectsPageGrade = atom<number>({
  key: 'curriculumSubjectsPageGrade',
  default: 1,
  effects: [
    syncEffect({
      refine: number(),
      itemKey: 'grade',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});

export const curriculumSubjectsPageSubject = atom<string>({
  key: 'curriculumSubjectsPageSubject',
  default: '',
  effects: [
    syncEffect({
      refine: string(),
      itemKey: 'subject',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});

export const curriculumSubjectsPageLeftPanelOpen = atom<boolean>({
  key: 'curriculumSubjectsPageLeftPanelOpen',
  default: true,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'leftPanelOpen',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});

export const curriculumSubjectsPageSelectedContentIds = atom<readonly string[]>(
  {
    key: 'curriculumSubjectsPageSelectedContentIds',
    default: [],
    effects: [
      syncEffect({
        refine: array(string()),
        itemKey: 'contentIds',
        storeKey: CURRICULUM_PAGE_STORE_KEY,
      }),
    ],
  }
);

export const curriculumSubjectsPageSelectedObjectiveIds = atom<
  readonly string[]
>({
  key: 'curriculumSubjectsPageSelectedObjectiveIds',
  default: [],
  effects: [
    syncEffect({
      refine: array(string()),
      itemKey: 'objectiveIds',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});

export const curriculumLinksState = atom<string | null | undefined>({
  key: 'curriculumLinksState',
  default: undefined,
  effects: [
    syncEffect({
      refine: nullable(string()),
      itemKey: 'selectedBulletpointId',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});

export const curriculumContentSelectorObjectiveMode = atom<boolean>({
  key: 'curriculumContentSelectorObjectiveMode',
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'objectiveMode',
      storeKey: CURRICULUM_PAGE_STORE_KEY,
    }),
  ],
});
