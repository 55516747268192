import { Box, Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStats,
} from '../../../api/contentprogressapi';
import { CenterLoader } from '../../../components/CenterLoader';
import {
  LARGE_SPACING,
  LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR,
  XXL_SPACING,
} from '../../../theme';
import { findBulletpointDescFromSubjects } from '../../../utils/ops/findBulletpointDescFromSubjects';
import { getSubjectsByGrade } from '../../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../../utils/ops/utils';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import {
  LessonItem,
  LinkItem,
  SubjectItem,
} from '../../ops/components/types/types';
import { ContentProgressBulletpointStats, Params } from '../types';
import { ActivityTimeline } from './ActivityTimeline';
import { ActivityOrderType, Note } from './ActivityOrder';
import { LibraryHeader } from './LibraryHeader';
import { LibraryTask } from './LibraryTask';
import { TimelineHeader } from './TimelineHeader';
import { useOps } from '../../../hooks/useOps';
import { Topic } from '../../../api/topicapi';

const fetchContentProgressStats = (params: Params) => {
  const obj = params.queryKey[1];
  return getAllStats(obj.groupId);
};

export const LessonTimeline = ({
  topic,
  setActivityOrNoteToOrder,
  deleteItemFromOrder,
  loader,
}: {
  topic: Topic;
  setActivityOrNoteToOrder: (
    orderSection: ActivityOrderType,
    orderNumber: number,
    lessonItem: LessonItem,
    isNew?: boolean
  ) => void;
  deleteItemFromOrder: (
    orderSection: ActivityOrderType,
    orderNumber: number
  ) => void;
  loader: boolean;
}) => {
  const { ops } = useOps();
  const [showCurriculumContent, setShowCurriculumContent] = useState(false);

  const { data: contentProgressStats } = useQuery({
    queryKey: [
      CONTENT_PROGRESS_API_QUERY_KEY,
      {
        groupId: topic.groupId,
      },
    ],
    queryFn: fetchContentProgressStats,
    ...QUERY_CONFIG,
    enabled: !!topic?.groupId,
  }) as { data: ContentProgressBulletpointStats[] };

  if (!ops || !contentProgressStats) {
    return <CenterLoader />;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: false })
  );

  const subjectData = subjects.find(
    (s) => s.code === topic.subjectId
  ) as SubjectItem;
  return (
    <Grid container style={{ width: '100%' }} sx={{ pt: '80px' }}>
      <Grid item xs={12} md={6} lg={4} sx={{ p: XXL_SPACING }}>
        <Box
          style={{
            backgroundColor: LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR,
            borderRadius: '20px',
          }}
          sx={{ p: LARGE_SPACING }}
        >
          <Stack>
            <LibraryHeader />
            <Box sx={{ mt: LARGE_SPACING }}>
              {topic.tasks.map((task, index) => (
                <LibraryTask
                  grade={subjectData?.grades?.find(
                    (g) => String(g.grade) === topic.gradeId
                  )}
                  activityOrder={topic.activityOrder}
                  key={`${task.contentProgressId + index}taskcpidkey`}
                  task={task}
                  taskText={findBulletpointDescFromSubjects(task, subjects)}
                  timesPlanned={
                    contentProgressStats?.find(
                      (cp) => cp.bulletpointId === task.bulletpointId
                    )?.timesPlanned ?? 0
                  }
                />
              ))}
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md lg sx={{ p: XXL_SPACING }}>
        <Box style={{}} sx={{ p: LARGE_SPACING }}>
          <Stack>
            <TimelineHeader
              {...{ showCurriculumContent, setShowCurriculumContent }}
            />
            <ActivityTimeline
              loader={loader}
              allTasks={topic.tasks}
              showCurriculumContent={showCurriculumContent}
              grade={topic.gradeId}
              subject={subjectData}
              deleteItemFromOrder={(orderSection, orderNumber) =>
                deleteItemFromOrder(orderSection, orderNumber)
              }
              activityOrder={topic.activityOrder}
              availableLinks={topic.tasks.flatMap(
                (task) => task.allAvailableLinks
              )}
              setActivityOrNoteToOrder={(
                orderSection: ActivityOrderType,
                orderNumber: number,
                activityOrNote: LinkItem | Note,
                isNew?: boolean
              ) =>
                setActivityOrNoteToOrder(
                  orderSection,
                  orderNumber,
                  activityOrNote,
                  isNew
                )
              }
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
