import React from 'react';
import { THEME_MAIN_TEXT_COLOR } from '../theme';

export enum IconSize {
  'EXTRA-SMALL',
  'SMALL',
  'SMALL-MEDIUM',
  'MEDIUM',
  'EXTRA-LARGE',
  'EXTRA-EXTRA-LARGE',
  'LARGE',
  '22-SIZE',
}

const resolveSize = (size: IconSize): string => {
  switch (size) {
    case IconSize['EXTRA-SMALL']:
      return '12px';
    case IconSize.SMALL:
      return '18px';
    case IconSize['SMALL-MEDIUM']:
      return '22px';
    case IconSize.MEDIUM:
      return '26px';
    case IconSize['EXTRA-LARGE']:
      return '52px';
    case IconSize['EXTRA-EXTRA-LARGE']:
      return '152px';
    case IconSize.LARGE:
    default:
      return '34px';
  }
};

export const FeatherIcon = ({
  name,
  size = IconSize.SMALL,
  color = THEME_MAIN_TEXT_COLOR,
  fill = false,
  style = {},
  onClick,
}: {
  name: string;
  size?: IconSize;
  color?: string;
  fill?: boolean;
  style?: Record<string, string | number>;
  onClick?: () => void;
}) => {
  const resolvedSize = resolveSize(size);
  return (
    <svg
      onClick={onClick}
      style={{
        width: resolvedSize,
        height: resolvedSize,
        fill: fill ? color : 'none',
        stroke: color,
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        ...style,
      }}
    >
      <use xlinkHref={`../../../../../../../../feather-sprite.svg#${name}`} />
    </svg>
  );
};
