import { atom } from 'recoil';
import { Group } from '../../api/groupapi';

export const groupEditorGroup = atom<Group | undefined>({
  key: 'groupEditorGroup',
  default: undefined,
});

export const groupEditorSelectedTimetableId = atom<string | undefined>({
  key: 'groupEditorSelectedTimetableId',
  default: undefined,
});

export const groupEditorTimetableSubjectWidth = atom<number>({
  key: 'groupEditorTimetableSubjectWidth',
  default: 150,
});
