import React from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Checkbox,
  Radio,
  FormControlLabel,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { THEME_MAIN_COLOR } from '../theme';
import { FeatherIcon } from './FeatherIcon';

export const ClearAllButton = ({ onClick }: { onClick: () => void }) => {
  const intl = useIntl();
  return (
    <Button
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
      onClick={onClick}
    >
      <Stack direction="row" gap="0.5em" alignItems="center">
        <FeatherIcon name="x" color="red" />
        <Box>{intl.formatMessage({ id: 'timetable.filters.clearAll' })}</Box>
      </Stack>
    </Button>
  );
};

const FilterOption = ({
  label,
  accent,
  active,
  obscured,
  onToggle,
  type,
}: {
  label: string;
  accent?: string;
  active: boolean;
  obscured?: boolean;
  onToggle: () => void;
  type?: 'checkbox' | 'radio';
}) => (
  <Stack
    direction="row"
    gap="15px"
    style={{
      opacity: obscured ? '50%' : '100%',
      fontSize: '14px',
    }}
  >
    <div
      style={{
        width: '8px',
        height: '43px',
        backgroundColor: accent ?? 'transparent',
      }}
    />
    <FormControlLabel
      control={
        type === 'radio' ? (
          <Radio checked={active} onChange={onToggle} />
        ) : (
          <Checkbox checked={active} onChange={onToggle} />
        )
      }
      label={label}
    />
  </Stack>
);

export const FilterCategoryLabel = ({ children }: { children: string }) => (
  <Typography
    variant="body2"
    style={{
      color: THEME_MAIN_COLOR,
      textTransform: 'uppercase',
      fontSize: '15px',
      font: 'RobotoMedium',
    }}
  >
    {children}
  </Typography>
);

export type FilterCategoryOption = {
  id: string;
  label: string;
  active: boolean;
  accent?: string;
};

export const FilterCategory = ({
  title,
  options,
  onToggle,
  obscured,
  type,
}: {
  title?: string;
  options: FilterCategoryOption[];
  onToggle: (id: string) => void;
  obscured?: boolean;
  type?: 'checkbox' | 'radio';
}) => (
  <Stack gap="1em">
    {title && <FilterCategoryLabel>{title}</FilterCategoryLabel>}
    <Stack gap="10px">
      {options.map(({ id, label, accent, active }) => (
        <FilterOption
          key={id}
          label={label}
          accent={accent}
          active={active}
          onToggle={() => onToggle(id)}
          obscured={obscured}
          type={type}
        />
      ))}
    </Stack>
  </Stack>
);
