import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { THEME_MAIN_COLOR } from '../../theme';
import { CenterLoader } from '../../components/CenterLoader';
import { CommonFullScreenDialog } from '../../components/CommonFullScreenDialog';

export const PictureCropDialog = ({
  open,
  onClose,
  src,
  uploadFile,
  loading,
  circularCrop = true,
  aspect,
  lockCrop = false,
  cropWidth = 200,
  cropHeight = 200,
  forwardRef,
}: {
  open: boolean;
  onClose: () => void;
  src: string;
  uploadFile: (buffer: ArrayBuffer) => void;
  loading?: boolean;
  circularCrop?: boolean;
  aspect?: number;
  lockCrop?: boolean;
  cropWidth?: number;
  cropHeight?: number;
  forwardRef?: any;
}) => {
  const intl = useIntl();
  const [cropParams, setCropParams] = useState({
    x: 130,
    y: 50,
    width: cropWidth,
    height: cropHeight,
    unit: 'px',
  });
  const imageRef = React.useRef<HTMLImageElement>(null);

  const onCropChange = (crop: Crop) => {
    setCropParams(crop);
  };

  const uploadCroppedImg = async (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx!!.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    await canvas.toBlob(
      (blob) => {
        blob?.arrayBuffer().then((arrayBuffer) => uploadFile(arrayBuffer));
      },
      'image/jpeg',
      1
    );
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      await uploadCroppedImg(imageRef.current, crop);
    }
  };

  const onCropComplete = () => {
    makeClientCrop(cropParams as Crop);
  };

  return (
    <CommonFullScreenDialog
      forwardRef={forwardRef}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        color="primary"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Typography variant="h6">Crop image</Typography>
      </DialogTitle>
      <DialogContent>
        {loading && <CenterLoader />}
        {!loading && (
          <ReactCrop
            locked={lockCrop}
            aspect={aspect}
            circularCrop={circularCrop}
            keepSelection={true}
            crop={{
              ...cropParams,
              unit: 'px',
            }}
            onChange={onCropChange}
            style={{ boxShadow: `0 0 6px ${THEME_MAIN_COLOR}` }}
          >
            <img src={src} ref={imageRef} alt="img" />
          </ReactCrop>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onCropComplete}
          color="primary"
          variant="contained"
        >
          {intl.formatMessage({ id: 'button.save' })}
        </Button>
      </DialogActions>
    </CommonFullScreenDialog>
  );
};
