import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { getGroups, Group } from '../../../api/groupapi';
import { groupEditorGroup } from '../../../App/state/group.state';
import { FilterButton } from '../../../components/FilterButton';
import { FilterItemBox } from '../../../components/FilterItemBox';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { parseNumber } from '../../../utils/string';
import { ImportGroupsDialog } from '../ImportGroupsDialog';
import { Phase } from '../phase';
import { GroupItem } from './GroupItem';
import { LARGE_SPACING, XXXL_SPACING } from '../../../theme';
import { CenterLoader } from '../../../components/CenterLoader';

export const ViewGroups = ({ setPhase }: { setPhase: (p: Phase) => void }) => {
  const intl = useIntl();
  const [_, setGroup] = useRecoilState(groupEditorGroup);
  const [showGroupFilters, setShowGroupFilters] = useState(false);
  const [filterGrade, setFilterGrade] = useState<string | undefined>(undefined);
  const [importGroupsDialogOpen, setImportGroupsDialogOpen] = useState(false);
  const groupsResponse = useQuery({
    queryKey: ['groupapi'],
    queryFn: getGroups,
    ...QUERY_CONFIG,
  });
  if (!groupsResponse.data) {
    return <CenterLoader />;
  }
  // @ts-ignore
  const groups: Group[] = groupsResponse?.data?.length
    ? groupsResponse.data
    : [];

  return (
    <>
      <ImportGroupsDialog
        open={importGroupsDialogOpen}
        onClose={() => setImportGroupsDialogOpen(false)}
      />
      <Grid container spacing={2} sx={{ mt: -4 }}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          sx={{ ml: XXXL_SPACING }}
        >
          <Typography variant="h5" style={{ color: 'grey' }}>
            My groups
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            marginLeft: '-25px',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <Box style={{ marginRight: 'auto' }} sx={{ ml: LARGE_SPACING }}>
            <FilterButton
              click={() => setShowGroupFilters(!showGroupFilters)}
            />
          </Box>
          <Button
            style={{ marginRight: '10px' }}
            variant="contained"
            color="secondary"
            onClick={() => setImportGroupsDialogOpen(true)}
          >
            {intl.formatMessage({ id: 'buttons.importGroups' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setGroup(undefined);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              setPhase && setPhase(Phase.NAME);
            }}
          >
            {intl.formatMessage({ id: 'buttons.addNewGroup' })}
          </Button>
        </Grid>
        {showGroupFilters && (
          <Grid item xs={12}>
            <Box
              sx={{ padding: 2, mb: 2 }}
              style={{
                marginLeft: '-16px',
                backgroundColor: '#FFFFFF',
                border: '0.5px solid',
                borderRadius: '4px',
              }}
            >
              <Typography
                sx={{ ml: 2 }}
                variant="body2"
                style={{ color: 'gray' }}
              >
                <b>FILTER GRADE</b>
              </Typography>
              <div>
                {[...new Set(groups.map((g) => g.grade))]
                  .filter((g) => !!g)
                  .sort((a, b) => parseNumber(a!!)!! - parseNumber(b!!)!!)
                  .map((grade) => (
                    <Button
                      key={`filter${grade}grade`}
                      onClick={() =>
                        filterGrade === grade
                          ? setFilterGrade(undefined)
                          : setFilterGrade(grade)
                      }
                    >
                      <FilterItemBox
                        key={`filter${grade}grade`}
                        text={grade!!}
                        selected={filterGrade === grade}
                      />
                    </Button>
                  ))}
              </div>
            </Box>
          </Grid>
        )}
        <Grid container spacing={2}>
          {groups
            .filter((g) => !filterGrade || g.grade === filterGrade)
            .map((group) => (
              <GroupItem
                setPhase={(p) => setPhase(p)}
                group={group}
                key={group.groupId}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
};
