import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { BOLD_FONT_WEIGHT, SMALL_SPACING } from '../../../theme';

export const LibraryHeader = () => {
  const intl = useIntl();
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <FeatherIcon name="book-open" size={IconSize['SMALL-MEDIUM']} />
      <Typography variant="body1" sx={{ ml: SMALL_SPACING }}>
        <span style={{ fontWeight: BOLD_FONT_WEIGHT }}>
          {intl.formatMessage({
            id: 'lessonplan.activityLibrary.header.activity',
          })}
        </span>{' '}
        {intl.formatMessage({
          id: 'lessonplan.activityLibrary.header.contents',
        })}
      </Typography>
    </Box>
  );
};
