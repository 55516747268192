import { TOPIC_API_URL } from './constants';
import { ContentProgress } from '../routes/my-plan/types';
import { apiGet, apiPost, apiPut } from './genericapi';
import { ActivityOrder } from '../routes/my-plan/lesson-plan/ActivityOrder';

export interface TopicBaseFields {
  name: string;
  description: string;
  groupId: string;
  activityOrder: ActivityOrder;
  year: number;
  lessonNumber: number;
  gradeId: string;
  subjectId: string;
}

export interface Topic extends TopicBaseFields {
  topicId: string;
  tasks: ContentProgress[];
}

export interface TaskRequest {
  contentId: string;
  bulletpointId: string;
  subjectId: string;
  gradeId: string;
  objectiveId: string;
}

export interface TopicRequest extends TopicBaseFields {
  tasks: TaskRequest[];
}

export const TOPICS_QUERY_KEY = 'topics';

export const postTopic = (payload: TopicRequest): Promise<any> =>
  apiPost(TOPIC_API_URL, payload);

export const putTopic = (
  payload: TopicRequest & { topicId: string }
): Promise<any> => apiPut(`${TOPIC_API_URL}/${payload.topicId}`, payload);

export const getTopics = (groupId: string): Promise<any> =>
  apiGet(`${TOPIC_API_URL}?groupId=${groupId}`);
