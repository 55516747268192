import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  FilterCategory,
  ClearAllButton,
} from '../../components/FilterCategory';
import { useSubjectColor } from '../../hooks/useSubjectColor';
import { PanelContentDivider } from '../../components/PanelContentDivider';

export const ScheduleFilterSelection = ({
  groups,
  subjects,
  setGroupFilter,
  setSubjectFilter,
  activeGroups,
  activeSubjects,
  filterGroup,
  filterSubject,
}: {
  groups: { groupId: string; name: string }[];
  subjects: string[];
  setGroupFilter: (groupIds: string[]) => void;
  setSubjectFilter: (subjectIds: string[]) => void;
  activeGroups: (string | undefined)[];
  activeSubjects: (string | undefined)[];
  filterGroup: readonly string[];
  filterSubject: readonly string[];
}) => {
  const { subjectColors } = useSubjectColor(subjects);
  const intl = useIntl();
  return (
    <>
      <Box>
        <Box
          style={{
            float: 'right',
            marginRight: '1em',
          }}
        >
          <ClearAllButton
            onClick={() => {
              setGroupFilter([]);
              setSubjectFilter([]);
            }}
          />
        </Box>

        <FilterCategory
          title={intl.formatMessage({ id: 'routes.groups' })}
          options={groups.map((g) => ({
            id: g.groupId,
            label: g.name,
            active: !!(
              (filterGroup ?? []).length > 0 && filterGroup?.includes(g.groupId)
            ),
            obscured:
              filterSubject !== undefined && !activeGroups.includes(g.groupId),
          }))}
          onToggle={(id) =>
            setGroupFilter(
              filterGroup.includes(id)
                ? filterGroup.filter((x) => x !== id)
                : [...filterGroup, id]
            )
          }
        />
        <PanelContentDivider />
        <FilterCategory
          title={intl.formatMessage({ id: 'opsPage.ops.subjects' })}
          options={subjects.map((s) => ({
            id: s,
            label: s,
            accent: subjectColors[s].primaryColor,
            active: filterSubject.includes(s),
            obscured: filterGroup !== undefined && !activeSubjects.includes(s),
          }))}
          onToggle={(s) =>
            setSubjectFilter(
              filterSubject.includes(s)
                ? filterSubject.filter((x) => x !== s)
                : [...filterSubject, s]
            )
          }
        />
      </Box>
    </>
  );
};
