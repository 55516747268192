import {
  Box,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  MEDIUM_SPACING,
} from '../../../theme';
import { ActivityTypeSelection } from './ActivityTypeSelection';
import { BookSelector } from '../../../components/BookSelector';
import { ActivityImageLoader } from '../../../components/ActivityImageLoader';
import {
  Book,
  BOOKS_QUERY_KEY,
  getBookBySubjectAndGrade,
} from '../../../api/booksapi';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { Params } from '../types';

type Props = {
  setActivityType: (type: string) => void;
  activityType: string;
  privateLink: boolean;
  setPrivateLink: (publicLink: boolean) => void;
  setLinkTitle: (linkTitle: string) => void;
  title?: string;
  grade: string;
  subject: string;
  setBookId: (bookId: string) => void;
  setBookTitle: (bookTitle: string) => void;
  selectedBookId?: string;
  setDesc: (desc: string) => void;
  desc: string;
  setLink: (link: string) => void;
  link?: string;
  setPage: (page: string) => void;
  page?: string;
  linkId?: string;
  imageUrl?: string;
  disablePortalForBookSelector?: boolean;
  children?: JSX.Element | React.ReactNode;
  uploadActivityImage: (
    buffer: any,
    callbackFn: () => void,
    errorCallbackFn: () => void,
    linkId?: string
  ) => void;
  deleteImage: (callbackFn: () => void, linkId?: string) => void;
};

const fetchBooks = (params: Params) => {
  const obj = params.queryKey[1];
  return getBookBySubjectAndGrade(obj.subject, obj.grade);
};

export const ActivityDetails = ({
  setActivityType,
  activityType,
  privateLink,
  setPrivateLink,
  setLinkTitle,
  title,
  grade,
  subject,
  setBookId,
  setBookTitle,
  selectedBookId,
  setDesc,
  desc,
  setLink,
  link,
  setPage,
  page,
  linkId,
  imageUrl,
  disablePortalForBookSelector = false,
  children,
  uploadActivityImage,
  deleteImage,
}: Props) => {
  const booksResponse = useQuery({
    queryKey: [
      BOOKS_QUERY_KEY,
      {
        grade,
        subject,
      },
    ],
    queryFn: fetchBooks,
    ...QUERY_CONFIG,
  });
  if (booksResponse?.isLoading) {
    return <CircularProgress />;
  }
  const intl = useIntl();
  const books: Book[] = booksResponse?.data ?? [];
  return (
    <Stack>
      <Box>
        <ActivityTypeSelection
          activityType={activityType}
          setActivityType={(type: string) => {
            setActivityType(type);
          }}
        />
      </Box>
      <Box
        style={{ borderBottom: '1px solid #AFBBC4' }}
        sx={{ mb: MEDIUM_SPACING }}
      >
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              checked={!privateLink}
              onChange={() => setPrivateLink(!privateLink)}
              name="privateLink"
            />
          }
          label={
            <Typography variant="body2">
              {intl.formatMessage({ id: 'form.showActivityToOthers' })}
            </Typography>
          }
        />
      </Box>
      {children}
      <Box sx={{ mx: LARGE_SPACING, mb: LARGE_SPACING }}>
        <Typography
          variant="body2"
          style={{ fontWeight: BOLD_FONT_WEIGHT }}
          sx={{ mb: LARGE_SPACING }}
        >
          {intl.formatMessage({ id: 'form.addActivityDetails' })}
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {activityType !== 'BOOK' && (
            <TextField
              label={
                <Typography variant="caption">
                  {intl.formatMessage({ id: 'form.title' })}*
                </Typography>
              }
              variant="outlined"
              value={title}
              onChange={(evt) => setLinkTitle(evt.target.value)}
              style={{ minWidth: '40%', backgroundColor: 'white' }}
            />
          )}
          {activityType === 'BOOK' && (
            <BookSelector
              disablePortal={disablePortalForBookSelector}
              books={books}
              grade={grade}
              subject={subject}
              setSelectedBook={(book: Book | undefined) => {
                setBookId(book?.bookId ?? '');
                setBookTitle(book?.title ?? '');
              }}
              selectedBook={books.find((b) => b.bookId === selectedBookId)}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mx: LARGE_SPACING,
          display: 'flex',
          justifyContent:
            linkId && activityType === 'FUNCTIONAL' ? 'center' : 'start',
          alignItems: 'start',
          gap: '10px',
          mb: activityType === 'FUNCTIONAL' ? 0 : LARGE_SPACING,
        }}
      >
        <TextField
          style={{ backgroundColor: 'white' }}
          multiline={true}
          minRows={2}
          maxRows={3}
          label={
            <Typography variant="caption">
              {intl.formatMessage({ id: 'form.desc' })}
            </Typography>
          }
          variant="outlined"
          onChange={(evt) => setDesc(evt.target.value)}
          value={desc}
          fullWidth
          inputProps={{ maxLength: 2048 }}
        />
        {activityType === 'FUNCTIONAL' && (
          <ActivityImageLoader
            linkId={linkId}
            imageUrl={imageUrl}
            uploadActivityImage={uploadActivityImage}
            deleteImage={deleteImage}
          />
        )}
      </Box>
      {activityType === 'BOOK' && (
        <Box
          sx={{
            mx: LARGE_SPACING,
            display: 'flex',
            justifyContent: linkId ? 'center' : 'start',
            alignItems: 'start',
            gap: '10px',
          }}
        >
          <TextField
            style={{ backgroundColor: 'white' }}
            label={
              <Typography variant="caption">
                {intl.formatMessage({ id: 'form.pageOrChapter' })}
              </Typography>
            }
            variant="outlined"
            onChange={(evt) => setPage(evt.target.value)}
            value={page}
            fullWidth
          />
          <ActivityImageLoader
            linkId={linkId}
            imageUrl={imageUrl}
            uploadActivityImage={uploadActivityImage}
            deleteImage={deleteImage}
          />
        </Box>
      )}
      {activityType === 'LINK' && (
        <Box
          sx={{
            mx: LARGE_SPACING,
            display: 'flex',
            justifyContent: linkId ? 'center' : 'start',
            alignItems: 'start',
            gap: '10px',
          }}
        >
          <TextField
            style={{ backgroundColor: 'white' }}
            label={
              <Typography variant="caption">
                {intl.formatMessage({ id: 'form.webAddress' })}
              </Typography>
            }
            placeholder="http://www.google.com"
            variant="outlined"
            onChange={(evt) => setLink(evt.target.value)}
            value={link}
            fullWidth
          />
          <ActivityImageLoader
            linkId={linkId}
            imageUrl={imageUrl}
            uploadActivityImage={uploadActivityImage}
            deleteImage={deleteImage}
          />
        </Box>
      )}
    </Stack>
  );
};
