export const messagesFI = {
  'application.name': 'Polarhub',
  'application.title': 'Polarhub',

  'navigation.register.title': 'Rekisteröidy',
  'navigation.login.title': 'Kirjaudu',
  'navigation.users.title': 'Käyttäjät',

  'dialog.submit.title.save': 'Tallenna',
  'dialog.confirm.title': 'Vahvista',
  'dialog.confirm.description':
    'Oletko varma, että haluat suorittaa toiminnon?',
  'dialog.button.ok': 'Ok',
  'dialog.button.cancel': 'Peruuta',

  'search.startTime': 'Alkaen',
  'search.endTime': 'Päättyen',

  'footer.itemCount': 'Tuloksia',

  'dashboardPage.table.title': 'Mitä uutta',

  'alert.title.error': 'Virhe',
  'alert.title.success': 'Onnistui',
  'alert.message.fetch': 'Listaus',
  'alert.message.search': 'Hakutoiminto',
  'alert.message.create': 'Luonti',
  'alert.message.update': 'Päivitys',
  'alert.message.delete': 'Poisto',

  'form.add': 'Lisää',
  'form.delete': 'Poista',
  'form.submit': 'Lähetä',
  'form.save': 'Tallenna',
  'form.cancel': 'Palaa',
  'form.login': 'Kirjaudu',
  'form.logout': 'Kirjaudu ulos',
  'form.showActivityToOthers': 'Näytä aktiviteetti muille',
  'form.addActivityDetails': 'Lisää aktiviteetin tiedot',
  'form.title': 'Otsikko',
  'form.desc': 'Kuvaus',
  'form.pageOrChapter': 'Sivu tai luku',
  'form.webAddress': 'Web-osoite',
  'form.dADPicture': 'Raahaa ja pudota kuva tai ',
  'form.choosePicture': 'Valitse kuva kansiosta',

  'value.unknown': 'Ei tiedossa',
  'value.active.true': 'Aktiivinen',
  'value.active.false': 'Inaktiivinen',
  'value.private.true': 'Yksityinen',
  'value.private.false': 'Julkinen',

  'registerPage.table.title': 'Rekisteröidy',
  'registerPage.table.username': 'Käyttäjätunnus',
  'registerPage.table.password': 'Salasana',
  'registerPage.table.firstname': 'Etunimi',
  'registerPage.table.lastname': 'Sukunimi',
  'registerPage.link.signin': 'Kirjaudu sisään',

  'registerPage.form.username': 'Sähköpostiosoite',

  'loginPage.table.title': 'Kirjaudu',
  'loginPage.table.username': 'Käyttäjätunnus',
  'loginPage.table.password': 'Salasana',
  'loginPage.link.signup': 'Rekisteröidy',
  'loginPage.snackbar.message.failed': 'Kirjautuminen epäonnistui',

  'opsPage.dialog.title': 'OPS',
  'opsPage.table.title': 'OPS',
  'opsPage.ops.subjects': 'Oppiaineet',
  'opsPage.ops.grades': 'Luokkatasot',
  'opsPage.ops.objectives': 'Tavoitteet',
  'opsPage.ops.contents': 'Oppimistavoitteet',
  'opsPage.ops.transversals': 'Taidot',
  'opsPage.filter.onlyFavorites': 'Vain suosikit',
  'opsPage.filter.onlyGrades': 'Vain luokat',
  'opsPage.ops.lessonTopic': 'Oppitunnin aihe',
  'opsPage.ops.lessonPlan': 'Tuntisuunnitelma',
  'opsPage.ops.content': 'Oppimistavoitteet',
  'opsPage.ops.lessons': 'Oppitunteja',

  'opsPage.dialog.form.field.title': 'Nimi',
  'opsPage.dialog.form.field.type': 'Tyyppi',
  'opsPage.dialog.form.field.link': 'Link',
  'opsPage.dialog.form.field.private': 'Yksityinen',
  'opsPage.dialog.form.field.description': 'Kuvaus',

  'opsPage.form.date': 'Päivämäärä',

  'usersPage.dialog.title': 'Käyttäjän tiedot',
  'usersPage.table.title': 'Käyttäjä',
  'usersPage.table.header.username': 'Käyttäjätunnus',
  'usersPage.table.header.description': 'Lisätietoja',
  'usersPage.table.header.password': 'Salasana',
  'usersPage.table.header.password_confirmation': 'Vahvista salasana',
  'usersPage.table.header.firstname': 'Etunimi',
  'usersPage.table.header.lastname': 'Sukunimi',
  'usersPage.table.header.email': 'Sähköposti',
  'usersPage.table.header.status': 'Tila',
  'usersPage.table.header.lastloginAt': 'Kirjautunut',
  'usersPage.table.header.createdAt': 'Liittynyt',
  'usersPage.table.header.yourGroups': 'Sinun ryhmäsi',

  'subject.subject': 'Aine',
  'subject.grade': 'Luokka',
  'subject.startAt': 'Alkaen',
  'subject.endAt': 'Päättyen',

  'snackBar.topicName.failed': 'Oppitunnin aiheen päivitys epäonnistui',
  'snackBar.topicName.updated': 'Oppitunnin aihe päivitetty',
  'snackBar.topicName.created': 'Oppitunnin aihe on luotu',
  'snackBar.topicCreate.failed': 'Tuntisuunnitelman luonti epäonnistui',
  'snackBar.topicCreate.created': 'Tuntisuunnitelma on luotu',

  'timetable.startTime': 'Alkaen',
  'timetable.endTime': 'Päättyen',
  'timetable.title': 'Lukujärjestys',

  'timetable.filters.clearAll': 'Poista valinnat',

  'timetable.select.title': 'Luo uusi lukujärjestys',
  'timetable.select.titleExisting': 'Luo uusi lukujärjestys tai muokkaa vanhaa',
  'timetable.select.create': 'Luo uusi',
  'timetable.select.existing': 'Valitse olemassaoleva lukujärjestys',

  'myplanpage.header.content': 'Sisältö',
  'myplanpage.header.calendar': 'Kalenteri',

  'myplancalendarpage.skills': 'Kohteiden ajastus viikolle',
  'myplancalendarpage.no-skills':
    'Kohteita ei ole valittu ajstettavaksi. Valitse kohteita sisältö-välilehdellä',

  'select-week': 'Valitse viikko',
  'select-day': 'Valitse päivä',

  'frontpage.tabs.week': 'Viikko',
  'frontpage.tabs.day': 'Päivä',

  'schedule.controls.weekend': 'Näytä viikonloppu',
  'schedule.controls.today': 'TÄMÄ VIIKKO',

  'schedule.controls.weekAbbrev': 'VK',

  'days.mon': 'ma',
  'days.tue': 'ti',
  'days.wed': 'ke',
  'days.thu': 'to',
  'days.fri': 'pe',
  'days.sat': 'la',
  'days.sun': 'su',

  'curriculumpage.subjects': 'Aineet',
  'curriculumpage.pedagogy': 'Pedagogia',
  'curriculumpage.assessment': 'Arviointi',
  'curriculumpage.support': 'Tuki',

  'curriculumsubjectspage.title': 'Opetussuunnitelma',
  'curriculumsubjectspage.tabs.read': 'Lue',
  'curriculumsubjectspage.tabs.select': 'Valitse',
  'curriculumsubjectspage.noSubject': 'Ei valittua oppiainetta',
  'curriculumsubjectspage.noSubjectSelect':
    'Valitse oppiaine ja luokkataso valikosta!',
  'curriculumsubjectspage.activitiesFor': 'Aktiviteetit oppimistavoitteelle',
  'curriculumsubjectspage.activity': 'Aktiviteetti',
  'curriculumsubjectspage.noActivity': 'Ei aktiviteettia',

  'button.delete': 'Poista',
  'button.save': 'Tallenna',
  'button.edit': 'Muokkaa',
  'button.cancel': 'Peruuta',

  'button.confirmDelete.title': 'Oletko varma poistosta?',
  'button.confirmDelete.content': 'Toiminto on pysyvä',

  'timetable.validator.isbefore':
    'Lukujärjestyksen päättymispäivän ei pitäisi olla ennen alkamispäivää',
  'timetable.validator.overlapping':
    'sisältää päällekkäisen alkamis-tai päättymispäivän',
  'websocket.profile.image.updated': 'Avatar luotu onnistuneesti',
  'websocket.profile.image.failed': 'Avatarin luonti epäonnistui',
  'groupPage.newTimetable': 'Uusi lukujärjestys',
  'groups.student.dialog.error.existing': 'Oppilas on jo lisätty ryhmään',
  'groups.student.dialog.error.email': 'Sähköposti on virheellinen',
  'myplan.topic.confirmation.new.title':
    'Haluatko aloittaa uuden aiheen luonnin?',
  'notifications.genericError': 'Tapahtui virhe',
  'notifications.activity.created': 'Aktiviteetti luotu',
  'notifications.activity.modified': 'Aktiviteettia muokattu',
  'notifications.activity.removed': 'Aktiviteetti poistettu',
  'buttons.importGroups': 'Tuo ryhmä',
  'buttons.addNewGroup': 'Lisää ryhmä',
  'buttons.addContent': 'Lisää oppimistavoitteet',
  'buttons.editLessonPlan': 'Muokkaa tunnin suunnitelmaa',
  'buttons.createLessonPlan': 'Luo tunnille suunnitelma',
  'buttons.showCurriculumContents': 'Näytä oppimistavoitteet',
  'buttons.hideCurriculumContents': 'Piilota oppimistavoitteet',
  'importGroupsDialog.title': 'Tuo ryhmiä',
  'importGroupsDialog.content':
    'CSV pitäisi olla seuraavat kentät: startDate, endDate, weekDay, time, length, groupName, grade, subject, teacher',
  'importGroupsDialog.uploadButton': 'Valitse CSV',
  'importGroupsDialog.saveButton': 'Tallenna',
  'importGroupsDialog.validationError':
    'CSV:tä prosessoidessa tapahtui virhe. Kenttä: ',
  'importGroupsDialog.groupsFound':
    'Ryhmiä löytyi: {groups}, Lukujärjestyksiä: {timetables}',
  'notifications.importGroupsDialog.importError':
    'Ryhmiä tuodessa tapahtui virhe, tarkasta ryhmät',
  'notifications.importGroupsDialog.importSuccess':
    'Ryhmät tuotu onnistuneesti',
  'button.done': 'Valmis',
  'groups.delete.confirm': 'Oletko varma että haluat poistaa ryhmän?',
  'notifications.group.deleted': 'Ryhmä poistettu onnistuneesti',
  'topicHeader.instruction': 'Luo tuntisuunnitelma valitsemalla aiheita',
  'topicHeader.instruction.externalTopic': 'Valitse tehtäviä aihetta varten',
  'topicContainer.addToLesson': 'Lisää tunnille',
  'routes.home': 'Koti',
  'routes.curriculum': 'ops',
  'routes.myplan': 'Oma suunnitelma',
  'routes.groups': 'Ryhmät',
  'routes.progress': 'Kehitys',
  'routes.login': 'Kirjaudu',
  'routes.logout': 'Kirjaudu ulos',
  'topicContainer.noLessonPlan': 'Ei suunnitelmaa tunnille',

  'lessonPanel.noCurriculumContents': 'Opetustavoitteet puuttuu',
  BOOK: 'Kirja:',
  FUNCTIONAL: 'Toiminnallinen tehtävä:',
  LINK: 'Linkki:',
  'topicPanel.curriculumContent': 'Opintosuunnitelman kohteet',
  'schedule.buttons.nextWeek': 'Seuraava viikko',
  'annualPlanPage.noPlan': 'Ei valittua vuosisuunnitelmaa',
  'panelMobile.groupSubjectTitle': 'Ryhmät ja Oppiaineet',
  'panelMobile.gradeSubjectTitle': 'Luokkataso ja Oppiaine',
  'yearplan.contentmodal.contenttab': 'Oppimistavoitteet',
  'yearplan.contentmodal.lessonplantab': 'Tuntisuunnitelma',
  'yearplan.contentmodal.contentAreasTab': 'Sisältöalueet',
  'yearplan.contentmodal.objectivesTab': 'Tavoitteet',
  'yearplan.contentmodal.noDate': 'Oppituntia ei ole aikataulutettu',
  'yearplan.contentmodal.all': 'Kaikki',
  'activityEditor.header.linkCurriculum':
    'Yhdistä aktiviteetti yhteen tai useaan oppimistavoitteeseen',
  'topic.placeholderName': 'Oppitunnin aihe',
  'noteEditor.header': 'Lisää huomio',
  'noteEditor.editHeader': 'Muokkaa huomiota',
  'noteEditor.secondHeader': 'Huomion sisältö',
  'noteEditor.name': 'Huomion nimi (valinnainen)',
  'noteEditor.showToStudents': 'Näytä oppilaille',
  'noteEditor.description': 'Kirjoita tähän',
  'addActivity.header': 'Lisää aktiviteetti',
  'editActivity.header': 'Muokkaa aktiviteettia',
  'noteOrActivity.removeFromLesson': 'Poista tunnilta',
  'noteOrActivity.remove': 'Poista',
  'noteOrActivity.edit': 'Muokkaa',
  'bookSelector.label': 'Valitse kirja',
  'bookSelector.addNewBook.prefix': 'Lisää',
  'profileEditor.header': 'Muokkaa profiilia',
  'profileEditor.subHeader': 'Henkilökohtaiset tiedot',
  'profileMenu.changeLocale': 'Vaihda kieli',
  'profileMenu.editProfile': 'Muokkaa profiilia',

  'lessonplan.timeline.header': 'Oppitunnin aikajana',
  'lessonplan.timeline.desc':
    'Suunnittele oppitunnin rakenne ja aktiviteetit oppitunnille.',
  'lessonplan.timeline.initiation': 'Aloitus',
  'lessonplan.timeline.practicing': 'Harjoittelu',
  'lessonplan.timeline.closing': 'Lopetus',
  'lessonplan.timeline.homework': 'Kotitehtävät',
  'lessonplan.activityLibrary.header.activity': 'Aktiviteettikirjasto',
  'lessonplan.activityLibrary.header.contents': 'ja oppimistavoitteet',
};
