import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Box, Stack, CircularProgress, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  MEDIUM_FONT_WEIGHT,
  XXL_SPACING,
  XXXL_SPACING,
} from '../../../theme';
import { SubjectItem } from '../../ops/components/types/types';
import { YearPlanCard } from './YearPlanCard';
import { YEAR_PLAN_CARD_BORDER, YEAR_PLAN_CARD_HEIGHT } from './Commons';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { YEAR_TIMELINE_CARD as CARD_Z_INDEX } from '../../../utils/z-index';
import { useTimetables } from '../../../hooks/useTimetables';
import { useTopics } from '../../../hooks/useTopics';
import { contentState } from '../../../App/state/year-plan.state';

const Heading = ({
  text,
  style = {},
}: {
  text: string;
  style?: Record<string, string | number>;
}) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...style,
    }}
    sx={{ mx: XXXL_SPACING, mt: XXL_SPACING }}
  >
    <Typography variant="body1" style={{ display: 'block' }}>
      {text}
    </Typography>
    {/* <FeatherIcon size={IconSize.SMALL} name="copy" color="#808285" /> */}
  </Box>
);

const YearCard = ({
  children,
  style,
}: {
  children: JSX.Element;
  style?: CSSProperties;
}) => (
  <Box
    style={{
      verticalAlign: 'top',
      display: 'inline-block',
      ...style,
    }}
  >
    {children}
  </Box>
);

export const YearTimeLine = ({
  subject,
  amountOfLessons,
  groupName,
  grade,
  groupId,
  year,
}: {
  subject: SubjectItem;
  amountOfLessons: number;
  groupName: string;
  grade: string;
  groupId: string;
  year: number;
}) => {
  const { topics, refetch: refetchTopics } = useTopics(groupId);
  const { subjectColor } = useSubjectColor(subject.code);
  const { timetables } = useTimetables(groupId);
  const intl = useIntl();
  if (!topics || !timetables) {
    return (
      <div
        style={{
          width: `calc(${window.innerWidth}px - (100vw/3))`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: YEAR_PLAN_CARD_HEIGHT,
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack
      direction="row"
      sx={{
        my: LARGE_SPACING,
      }}
      style={{
        background: '#245f7333 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #172b4d33',
        border: '1px solid #172B4D',
        borderRadius: '20px 0 0 20px',
      }}
    >
      <YearCard
        style={{
          boxShadow: ' 4px 0 8px 0 rgba(0, 0, 0, 0.6)',
          minWidth: '182px',
          // height: '100%',
          borderRight: YEAR_PLAN_CARD_BORDER,
          backgroundColor: subjectColor.secondaryColor,
          borderRadius: '20px 0 0 20px',
          zIndex: CARD_Z_INDEX,
        }}
      >
        <Box style={{ width: '100%', height: YEAR_PLAN_CARD_HEIGHT }}>
          <Box
            style={{
              justifyContent: 'center',
              height: `${80}px`,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
              marginTop: '10px',
            }}
          >
            {/*  <FeatherIcon name="edit-2" color="#B2B7B9" size={IconSize.SMALL} /> */}
            <Typography
              variant="body1"
              style={{
                fontSize: '18px',
                fontWeight: MEDIUM_FONT_WEIGHT,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              sx={{ ml: XXL_SPACING }}
            >
              {groupName}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                fontWeight: BOLD_FONT_WEIGHT,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              sx={{ ml: XXL_SPACING }}
            >
              {subject.title}
            </Typography>
          </Box>
          <Box
            style={{
              height: `60px`,
              width: '100%',
              borderTop: YEAR_PLAN_CARD_BORDER,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Heading
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: 0,
                marginTop: 0,
              }}
              text={intl.formatMessage({ id: 'opsPage.ops.lessonTopic' })}
            />
          </Box>
          <Box
            style={{
              height: `${YEAR_PLAN_CARD_HEIGHT / 2}px`,
              borderTop: YEAR_PLAN_CARD_BORDER,
              borderBottom: YEAR_PLAN_CARD_BORDER,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Heading text={intl.formatMessage({ id: 'opsPage.ops.content' })} />
            <Typography
              variant="caption"
              style={{ display: 'block' }}
              sx={{ ml: XXXL_SPACING }}
            >
              {intl.formatMessage({ id: 'opsPage.ops.lessons' })}
              {': '}
              {amountOfLessons}
            </Typography>
          </Box>
          <Box
            style={{
              width: '100%',
              borderTop: YEAR_PLAN_CARD_BORDER,
            }}
          >
            <Heading
              style={{ marginTop: '10px' }}
              text={intl.formatMessage({ id: 'opsPage.ops.lessonPlan' })}
            />
          </Box>
        </Box>
      </YearCard>
      <ScrollContainer
        hideScrollbars={false}
        vertical={false}
        horizontal={true}
        ignoreElements="input"
      >
        <Box
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {Array.from({ length: amountOfLessons }, (___, i) => i + 1).map(
            (lessonNumber) => (
              <Box
                style={{
                  display: 'inline-block',
                  border: '1px solid #AFBBC4',
                  borderRadius: '12px',
                  margin: LARGE_SPACING,
                  backgroundColor: subjectColor.secondaryColor,
                  marginTop: '9px',
                  boxShadow: '0px 3px 6px #00000029',
                }}
                key={`yearplanlessoncard-${lessonNumber}`}
              >
                <YearPlanCard
                  subject={subject}
                  grade={grade}
                  lessonNumber={lessonNumber}
                  amountOfLessons={amountOfLessons}
                  key={`yearplanlessoncard-${lessonNumber}`}
                  year={year}
                  groupId={groupId}
                  groupName={groupName}
                  timetables={timetables}
                  topics={topics}
                  refetchTopics={refetchTopics}
                  subjectColor={subjectColor}
                />
              </Box>
            )
          )}
        </Box>
      </ScrollContainer>
    </Stack>
  );
};
