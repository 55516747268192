import { NavigateFunction } from 'react-router-dom';
import { EnhancedSubjectTiming } from '../../api/timetablesapi';
import { getAmountOfLessonsForSubject } from '../my-plan/year-plan/yearPlanUtils';
import { parseNumber } from '../../utils/string';
import { getSchoolYear } from '../../utils/schoolyear.utils';
import { createScrollableId } from '../my-plan/year-plan/utils';
import { ContentViewState } from '../../App/state/year-plan.state';

export const scrollToContentFn = (
  lessonNumber: number,
  schoolYear: number,
  grade: string,
  subject: string
) => {
  const section = document.querySelector(
    `#${createScrollableId(lessonNumber!!, schoolYear, grade, subject)}`
  );
  section?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
};

export const redirectToYearPlanPageWithTopic = async (
  enhancedSubjectTiming: EnhancedSubjectTiming,
  setPanelOpenState: (val: boolean) => void,
  navigate: NavigateFunction,
  setContentViewState: (state: ContentViewState | {}) => void,
  setRedirectState: (val: string) => void,
  setActiveTab: (val: 'Content' | 'Lesson') => void,
  activeTab: 'Lesson' | 'Content',
  setDialogOpen: (val: boolean) => void
): Promise<void> => {
  const schoolYear = getSchoolYear(enhancedSubjectTiming.startAt);
  const { subject, grade, lessonNumber, groupName, groupId } =
    enhancedSubjectTiming;
  const gradeNumber = parseNumber(grade)!!;
  setPanelOpenState(false);
  setRedirectState('/home');
  setActiveTab(activeTab);
  setContentViewState({
    grade,
    subject,
    groupName: groupName!!,
    groupId: groupId!!,
    amountOfLessons: getAmountOfLessonsForSubject(subject, gradeNumber),
    lessonNumber: lessonNumber!!,
    year: schoolYear,
  });
  if (activeTab === 'Lesson') {
    setDialogOpen(true);
  }
  navigate(`/my-plan/year-plan`);
  await new Promise(() =>
    setTimeout(
      () => scrollToContentFn(lessonNumber!!, schoolYear, grade, subject),
      2000
    )
  );
};
