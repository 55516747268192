/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { EnhancedSubjectTiming } from '../../api/timetablesapi';
import { DETAIL_COLOR } from '../../theme';
import { useSubjectColor } from '../../hooks/useSubjectColor';
import { useAuth } from '../../utils/auth/useAuth';
import { FeatherIcon, IconSize } from '../../components/FeatherIcon';
import { SCHEDULE_SUBJECT as Z_INDEX } from '../../utils/z-index';
import { ActivityOrderTypes } from '../my-plan/lesson-plan/ActivityOrder';

export const ScheduleSubject = ({
  topMargin,
  height,
  subjectTiming,
  widthPercentage,
  leftPercentage,
  activeSubjectTiming,
  setActiveSubjectTiming,
}: {
  topMargin: string;
  height: number;
  subjectTiming: EnhancedSubjectTiming;
  widthPercentage: number;
  leftPercentage?: number;
  activeSubjectTiming?: EnhancedSubjectTiming;
  setActiveSubjectTiming: (st: string) => void;
}) => {
  const HEADER_HEIGHT = 60;
  const { user } = useAuth();
  const intl = useIntl();
  const useBorder =
    activeSubjectTiming?.subjectTimingId === subjectTiming?.subjectTimingId;
  const { subjectColor } = useSubjectColor(subjectTiming.subject);
  const showAlertIconInHeader = (
    cardheight: number,
    headerHeight: number
  ): boolean => {
    if (
      cardheight < headerHeight + headerHeight / 2 &&
      ((!!subjectTiming?.topic?.activityOrder &&
        !ActivityOrderTypes.some(
          (key) => subjectTiming.topic!!.activityOrder!![key]!!.length > 0
        )) ||
        !subjectTiming?.topic?.activityOrder)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        borderRadius: '8px',
        border: useBorder ? `${DETAIL_COLOR} 4px solid` : '',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        top: topMargin,
        height,
        width: `${widthPercentage - 10}%`,
        left: `${leftPercentage ? leftPercentage + 5 : 5}%`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        zIndex: Z_INDEX,
        cursor: 'pointer',
      }}
      onClick={() => setActiveSubjectTiming(subjectTiming.subjectTimingId!!)}
    >
      <div
        style={{
          borderLeft: `${subjectColor.primaryColor} 10px solid`,
          height: '100%',
          width: '100%',
        }}
      >
        {!!subjectTiming?.groupName && (
          <div
            style={{
              fontFamily: 'RobotoRegular',
              fontSize: '16px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              textAlign: 'left',
              color: '#172B4D',
              backgroundColor: subjectColor.secondaryColor,
              boxSizing: 'border-box',
              padding: '2px 6px 2px 6px',
              overflowWrap: 'break-word',

              height: `${HEADER_HEIGHT} px`,
            }}
          >
            <div
              style={{
                width: '50%',
                whiteSpace: 'nowrap',
              }}
            >
              <div
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {subjectTiming.groupName}
              </div>
              <div
                style={{
                  width: '200%',
                  fontFamily: 'RobotoMedium',
                  fontSize: '16px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {subjectTiming.subject}
              </div>
            </div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              {format(new Date(subjectTiming.startAt), 'HH:mm')}
              {user?.role === 'TEACHER' &&
                showAlertIconInHeader(height, HEADER_HEIGHT) && (
                  <Tooltip
                    placement="top"
                    title={intl.formatMessage({
                      id: 'tootips.lessonPlanProgress',
                    })}
                  >
                    <div>
                      <FeatherIcon
                        name="alert-circle"
                        color="red"
                        size={IconSize.MEDIUM}
                        style={{
                          background: `${subjectColor.secondaryColor}`,
                          position: `${
                            height > HEADER_HEIGHT / 1.5
                              ? 'relative'
                              : 'absolute'
                          }`,
                          top: '0',
                          right: '0px',
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
            </Box>
          </div>
        )}
        <Box
          sx={{ p: 2 }}
          style={{
            display: 'flex',
            justifyContent: 'left',
            textAlign: 'left',
            color: '#172B4D',
            textOverflow: 'ellipsis',
            fontSize: '19px',
          }}
        >
          <Typography variant="inherit" style={{ maxWidth: '100%' }}>
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
                display: 'block',
                fontFamily: 'RobotoMedium',
                fontSize: '16px',
              }}
            >
              {!!subjectTiming.topic &&
                subjectTiming.topic?.name !== 'Lesson topic' &&
                subjectTiming.topic?.name !== 'Oppitunnin aihe' &&
                subjectTiming.topic?.name}
            </div>
          </Typography>
        </Box>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '3px',
            justifyContent: 'left',
          }}
        >
          {(!subjectTiming?.topic?.tasks ||
            subjectTiming?.topic?.tasks.length === 0) &&
            user?.role === 'TEACHER' && (
              <Tooltip title={intl.formatMessage({ id: 'buttons.addContent' })}>
                <Box
                  sx={{
                    display: 'inline',
                    fontFamily: 'RobotoMedium',
                    fontSize: 14,
                    my: 'auto',
                  }}
                >
                  <Box sx={{ display: 'flex', fontSize: 16, p: '0.5px' }}>
                    <FeatherIcon
                      name="alert-circle"
                      color="red"
                      size={IconSize.MEDIUM}
                    />
                    <span style={{ padding: '0 0px 0px 6px' }}>
                      {' '}
                      {intl.formatMessage({ id: 'buttons.addContent' })}
                    </span>
                  </Box>
                </Box>
              </Tooltip>
            )}
          {subjectTiming.topic?.activityOrder &&
            !ActivityOrderTypes.some(
              (key) => subjectTiming.topic!!.activityOrder!![key]!!.length > 0
            ) &&
            user?.role === 'TEACHER' && (
              <Tooltip
                title={intl.formatMessage({ id: 'buttons.createLessonPlan' })}
              >
                <Box
                  sx={{
                    display: 'inline',
                    fontFamily: 'RobotoMedium',
                    fontSize: 14,
                    my: 'auto',
                  }}
                >
                  <Box sx={{ display: 'flex', fontSize: 16, p: '0.5px' }}>
                    <FeatherIcon
                      name="alert-circle"
                      color="red"
                      size={IconSize.MEDIUM}
                    />
                    <span style={{ padding: '0 0px 0px 6px' }}>
                      {' '}
                      {intl.formatMessage({ id: 'buttons.createLessonPlan' })}
                    </span>
                  </Box>
                </Box>
              </Tooltip>
            )}
        </div>
      </div>
    </div>
  );
};
