import { atom } from 'recoil';
import { SimpleSubject } from '../../routes/my-plan/helpers';

export const cardsOpen = atom<string[]>({
  key: 'cardsOpen',
  default: [],
});

export const selectedSubject = atom<SimpleSubject | undefined>({
  key: 'simpleSubject',
  default: undefined,
});
