import React from 'react';
import { useIntl } from 'react-intl';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import uniqBy from 'lodash.uniqby';
import {
  LessonItem,
  LinkItem,
  SubjectItem,
} from '../../ops/components/types/types';
import { ContentProgress } from '../types';
import { ActivityOrder, ActivityOrderType, Note } from './ActivityOrder';
import { TimelineSectionContent } from './TimelineSectionContent';
import { useOps } from '../../../hooks/useOps';
import { CenterLoader } from '../../../components/CenterLoader';

const TIMELINE_DOT_CONNECTOR_STYLE = { backgroundColor: 'black' };

export const ActivityTimeline = ({
  activityOrder,
  availableLinks,
  setActivityOrNoteToOrder,
  deleteItemFromOrder,
  grade,
  subject,
  allTasks,
  showCurriculumContent,
  loader,
}: {
  activityOrder: ActivityOrder;
  availableLinks: LinkItem[];
  setActivityOrNoteToOrder: (
    orderSection: ActivityOrderType,
    orderNumber: number,
    activityOrNote: LinkItem | Note,
    isNew?: boolean
  ) => void;
  deleteItemFromOrder: (
    orderSection: ActivityOrderType,
    orderNumber: number
  ) => void;
  grade: string;
  subject: SubjectItem;
  allTasks: ContentProgress[];
  showCurriculumContent: boolean;
  loader: boolean;
}) => {
  const { ops } = useOps();
  const intl = useIntl();
  if (!ops || loader) {
    return <CenterLoader />;
  }

  return (
    <Timeline
      // left align
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 0,
        },
      }}
    >
      <TimelineItem key="timelineitem-initiation">
        <TimelineSeparator>
          <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
          <TimelineConnector style={TIMELINE_DOT_CONNECTOR_STYLE} />
        </TimelineSeparator>
        <TimelineContent key="content-initiation">
          <TimelineSectionContent
            allTasks={allTasks}
            showCurriculumContent={showCurriculumContent}
            grade={grade}
            subject={subject}
            key="content-initiation-content"
            header={intl.formatMessage({
              id: 'lessonplan.timeline.initiation',
            })}
            activities={uniqBy(
              availableLinks?.filter(
                (availableLink) =>
                  availableLink?.linkId &&
                  activityOrder.initiation?.some(
                    (initiationLink) =>
                      initiationLink.activityId === availableLink.linkId
                  )
              ),
              'linkId'
            )}
            order={activityOrder.initiation}
            setActivityOrNote={(
              orderNumber: number,
              activityOrNote: LinkItem | Note,
              isNew?: boolean
            ) =>
              setActivityOrNoteToOrder(
                'initiation',
                orderNumber,
                activityOrNote,
                isNew
              )
            }
            deleteItemFromOrder={(orderNumber: number) =>
              deleteItemFromOrder('initiation', orderNumber)
            }
          />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem key="timelineitem-practicing">
        <TimelineSeparator>
          <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
          <TimelineConnector style={TIMELINE_DOT_CONNECTOR_STYLE} />
        </TimelineSeparator>
        <TimelineContent key="content-practicing">
          <TimelineSectionContent
            allTasks={allTasks}
            showCurriculumContent={showCurriculumContent}
            grade={grade}
            subject={subject}
            key="content-practicing-content"
            header={intl.formatMessage({
              id: 'lessonplan.timeline.practicing',
            })}
            activities={uniqBy(
              availableLinks.filter(
                (availableLink) =>
                  availableLink?.linkId &&
                  activityOrder.practicing?.some(
                    (practicingLink) =>
                      practicingLink.activityId === availableLink.linkId
                  )
              ),
              'linkId'
            )}
            order={activityOrder.practicing}
            setActivityOrNote={(
              orderNumber: number,
              activityOrNote: LessonItem,
              isNew?: boolean
            ) =>
              setActivityOrNoteToOrder(
                'practicing',
                orderNumber,
                activityOrNote,
                isNew
              )
            }
            deleteItemFromOrder={(orderNumber: number) =>
              deleteItemFromOrder('practicing', orderNumber)
            }
          />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem key="timelineitem-closing">
        <TimelineSeparator>
          <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
          <TimelineConnector style={TIMELINE_DOT_CONNECTOR_STYLE} />
        </TimelineSeparator>
        <TimelineContent key="content-closing">
          <TimelineSectionContent
            allTasks={allTasks}
            showCurriculumContent={showCurriculumContent}
            grade={grade}
            subject={subject}
            key="content-closing-content"
            header={intl.formatMessage({ id: 'lessonplan.timeline.closing' })}
            activities={uniqBy(
              availableLinks.filter(
                (availableLink) =>
                  availableLink?.linkId &&
                  activityOrder.closing?.some(
                    (closingLink) =>
                      closingLink.activityId === availableLink.linkId
                  )
              ),
              'linkId'
            )}
            order={activityOrder.closing}
            setActivityOrNote={(
              orderNumber: number,
              lessonItem: LessonItem,
              isNew?: boolean
            ) =>
              setActivityOrNoteToOrder(
                'closing',
                orderNumber,
                lessonItem,
                isNew
              )
            }
            deleteItemFromOrder={(orderNumber: number) =>
              deleteItemFromOrder('closing', orderNumber)
            }
          />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem key="timelineitem-homework">
        <TimelineSeparator>
          <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
        </TimelineSeparator>
        <TimelineContent key="content-closing">
          <TimelineSectionContent
            allTasks={allTasks}
            showCurriculumContent={showCurriculumContent}
            grade={grade}
            subject={subject}
            key="content-homework-content"
            header={intl.formatMessage({ id: 'lessonplan.timeline.homework' })}
            activities={uniqBy(
              availableLinks.filter(
                (availableLink) =>
                  availableLink?.linkId &&
                  activityOrder.homework?.some(
                    (closingLink) =>
                      closingLink.activityId === availableLink.linkId
                  )
              ),
              'linkId'
            )}
            order={activityOrder.homework}
            setActivityOrNote={(
              orderNumber: number,
              lessonItem: LessonItem,
              isNew?: boolean
            ) =>
              setActivityOrNoteToOrder(
                'homework',
                orderNumber,
                lessonItem,
                isNew
              )
            }
            deleteItemFromOrder={(orderNumber: number) =>
              deleteItemFromOrder('homework', orderNumber)
            }
          />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
