import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { groupEditorTimetableSubjectWidth } from '../../../App/state/group.state';
import { MAIN_BORDER } from './consts';

export const VisualHourItem = ({
  top,
  hour,
}: {
  top: string;
  hour?: string;
}) => {
  const [_, setBoxWidth] = useRecoilState(groupEditorTimetableSubjectWidth);
  const ref = useRef(null);
  useEffect(() => {
    setBoxWidth((ref as any).current.offsetWidth);
  }, [ref, setBoxWidth]);
  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        top,
        position: 'absolute',
        borderBottom: MAIN_BORDER,
        height: '60px',
        marginTop: '0px',
        marginBottom: '0px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'start',
      }}
    >
      {hour}
    </div>
  );
};
