export const createScrollableId = (
  lessonNumber: number,
  year: number,
  grade: string,
  subjectCode: string
): string =>
  `box-${lessonNumber}${year}${grade}${subjectCode
    .split(' ')
    .slice(0, subjectCode.length - 1)
    .join(' ')}`;
