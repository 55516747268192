/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CircularProgress } from '@mui/material';
import { add, getDay, isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import {
  createTimetable,
  SubjectTiming,
  TimeTable,
  updateTimetable,
} from '../../../api/timetablesapi';
import { groupEditorSelectedTimetableId } from '../../../App/state/group.state';
import { GroupTimetableHeader } from '../headers/GroupTimetableHeader';
import { DaySubject, GenerateTimetable, SubjectsForDays } from './interfaces';
import { TimetableEditor } from './TimetableEditor';
import { TimetableGenerator } from './TimetableGenerator';
import { timestampWithoutTimezone, timeToDate } from './utils';
import { useTimetables } from '../../../hooks/useTimetables';

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const daySubjectToSubjectTiming = (
  daySubject: DaySubject,
  date: Date
): SubjectTiming => ({
  subject: daySubject.subject.split('-').slice(0, -1).join('-'),
  grade:
    daySubject.subject.split('-')[daySubject.subject.split('-').length - 1],
  startAt: timestampWithoutTimezone(timeToDate(daySubject.from, date)),
  endAt: timestampWithoutTimezone(timeToDate(daySubject.to, date)),
});

export const subjectsForDaysToSubjectTiming = (
  from: Date,
  to: Date,
  subjects: SubjectsForDays
): SubjectTiming[] => {
  let subjectTimings: SubjectTiming[] = [];
  let iteratorDate = from;
  while (isBefore(iteratorDate, to)) {
    const day: string = DAYS[getDay(iteratorDate)];
    subjectTimings = [
      ...subjectTimings,
      ...(subjects[day]?.map((subject) =>
        daySubjectToSubjectTiming(subject, iteratorDate)
      ) ?? []),
    ];
    iteratorDate = add(iteratorDate, { days: 1 });
  }

  return subjectTimings;
};

export const Timetables = ({
  favoriteSubjects,
  previousPhase,
  groupId,
  nextPhase,
}: {
  favoriteSubjects: string[];
  previousPhase: () => void;
  groupId?: string;
  nextPhase: () => void;
}) => {
  const queryClient = useQueryClient();
  const { timetables } = useTimetables(groupId);
  const [selectedTimetableId, setSelectedTimetableId] = useRecoilState(
    groupEditorSelectedTimetableId
  );

  const { enqueueSnackbar } = useSnackbar();
  const createTimeTableMutation = useMutation(createTimetable);
  const updateTimetableMutation = useMutation(updateTimetable);

  if (!timetables) {
    return <CircularProgress />;
  }
  const selectedTimetable = selectedTimetableId
    ? (timetables as TimeTable[])?.filter(
        (tt) => tt.timetableId === selectedTimetableId
      )[0]
    : undefined;
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GroupTimetableHeader checked={timetables?.length} />
      </div>
      {selectedTimetableId && selectedTimetable ? (
        <TimetableEditor
          resetSelectedTimetableId={() => setSelectedTimetableId(undefined)}
          favoriteSubjects={favoriteSubjects}
          previousPhase={() => previousPhase()}
          timetable={selectedTimetable}
          saveTimetable={(t: TimeTable) => {
            updateTimetableMutation
              .mutateAsync({ ...t, groupId: groupId!! })
              .then(() => {
                queryClient.invalidateQueries();
                enqueueSnackbar('Timetable saved successfully', {
                  variant: 'success',
                });
                nextPhase();
              })
              .catch(() => {
                queryClient.invalidateQueries();
                enqueueSnackbar('Timetable saving failed', {
                  variant: 'error',
                });
              });
          }}
        />
      ) : (
        <TimetableGenerator
          favoriteSubjects={favoriteSubjects}
          previousPhase={() => previousPhase()}
          generateTimetable={(generateTimeTable: GenerateTimetable) => {
            createTimeTableMutation
              .mutateAsync({
                from: generateTimeTable.from.toISOString(),
                to: generateTimeTable.to.toISOString(),
                title: 'Timetable',
                groupId: groupId!!,
                times: subjectsForDaysToSubjectTiming(
                  generateTimeTable.from,
                  generateTimeTable.to,
                  generateTimeTable.subjectsForDays
                ),
              })
              .then((data) => {
                queryClient.invalidateQueries();
                setSelectedTimetableId(data.timetableId);
                enqueueSnackbar('Timetable saved successfully', {
                  variant: 'success',
                });
              })
              .catch(() => {
                queryClient.invalidateQueries();
                enqueueSnackbar('Timetable saving failed', {
                  variant: 'error',
                });
              });
          }}
        />
      )}
    </>
  );
};
