import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';
import {
  array,
  bool,
  date,
  nullable,
  number,
  object,
  string,
} from '@recoiljs/refine';
import { parseISO } from 'date-fns';

import { getWeekFromDay } from '../../utils/weekhelpers';
import { ScheduleWeek } from '../../generics/types/scheduleWeek';

const TODAY = new Date();

export const HOME_PAGE_STORE_KEY = 'homePage';

export const scheduleSelectedWeek = atom<ScheduleWeek>({
  key: 'scheduleSelectedWeek',
  default: getWeekFromDay(TODAY),
  effects: [
    syncEffect({
      refine: object({ startAt: date(), endAt: date(), weekNumber: number() }),
      itemKey: 'selectedWeek',
      storeKey: HOME_PAGE_STORE_KEY,
      read: ({ read }) => {
        const values: any = read('selectedWeek');
        if (
          values?.startAt &&
          values?.endAt &&
          values?.weekNumber &&
          typeof values?.startAt === 'string' &&
          typeof values?.endAt === 'string' &&
          typeof values?.weekNumber === 'number'
        ) {
          return {
            startAt: parseISO(values.startAt),
            endAt: parseISO(values.endAt),
            weekNumber: values.weekNumber,
          };
        }
        return getWeekFromDay(TODAY);
      },
    }),
  ],
});

export const scheduleViewClass = atom<string | null | undefined>({
  key: 'scheduleViewClass',
  default: undefined,
  effects: [
    syncEffect({
      refine: nullable(string()),
      itemKey: 'class',
      storeKey: HOME_PAGE_STORE_KEY,
    }),
  ],
});

export const schedulePlanPreviewDialogOpen = atom<boolean>({
  key: 'schedulePlanPreviewDialogOpen',
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'preview',
      storeKey: HOME_PAGE_STORE_KEY,
    }),
  ],
});

export const scheduleShowFilters = atom<boolean>({
  key: 'scheduleShowFilters',
  default: false,
  effects: [
    syncEffect({
      refine: bool(),
      itemKey: 'filters',
      storeKey: HOME_PAGE_STORE_KEY,
    }),
  ],
});

export const scheduleGroupFilter = atom<readonly string[]>({
  key: 'scheduleGroupFilter',
  default: [],
  effects: [
    syncEffect({
      refine: array(string()),
      itemKey: 'groups',
      storeKey: HOME_PAGE_STORE_KEY,
    }),
  ],
});

export const scheduleSubjectFilter = atom<readonly string[]>({
  key: 'scheduleSubjectFilter',
  default: [],
  effects: [
    syncEffect({
      refine: array(string()),
      itemKey: 'subjects',
      storeKey: HOME_PAGE_STORE_KEY,
    }),
  ],
});
