import React from 'react';
import { Route, useLocation, Navigate, Routes } from 'react-router-dom';
import { RecoilURLSyncJSON } from 'recoil-sync';
import { CurriculumPage } from '../routes/curriculum/CurriculumPage';
import { CurriculumSubjectsPage } from '../routes/curriculum/subjects/CurriculumSubjectsPage';
import { useAuth } from '../utils/auth/useAuth';
import { AdminPage } from '../routes/admin/AdminPage';
import { ProgressPage } from '../routes/progress/ProgressPage';
import { TransversalProgressPage } from '../routes/progress/TransversalProgressPage';
import { GroupsPage } from '../routes/groups/GroupsPage';
import { StudentProgressPage } from '../routes/progress/StudentProgressPage';
import { StudentTransversalProgressPage } from '../routes/progress/StudentTransversalProgressPage';
import { HomePage } from '../routes/home/HomePage';
import { SubjectProgressPage } from '../routes/progress/SubjectProgressPage';
import { YearPlanPage } from '../routes/my-plan/year-plan/YearPlanPage';
import { DeleteData } from '../routes/delete-data/DeleteData';
import { OAUTH_LOGIN } from '../api/constants';
import { CenterLoader } from '../components/CenterLoader';
import { HOME_PAGE_STORE_KEY } from '../App/state/home.state';
import { CURRICULUM_PAGE_STORE_KEY } from '../App/state/curriculum.state';
import { YEAR_PLAN_PAGE_STORE_KEY } from '../App/state/year-plan.state';

const RedirectToHomeIfLoggedIn = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

const NavigateToLogin = () => {
  if (window.location.hostname !== 'lvh.me') {
    window.location.replace(`${OAUTH_LOGIN}`);
  }
  return <CenterLoader />;
};

const RequireAuth = ({
  children,
  admin,
}: {
  children: JSX.Element;
  admin: boolean;
}) => {
  const location = useLocation();
  const { isLoggedIn, admin: isAdmin } = useAuth();

  if (!isLoggedIn && !location.hash.includes('callback')) {
    return <Navigate to="/login" />;
  }

  if (admin && !isAdmin) {
    // eslint-disable-next-line no-alert
    alert('Not authorized to view this page');
    return <Navigate to="/" />;
  }
  return children;
};

export const RootRoute = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/admin"
        element={
          <RequireAuth admin={true}>
            <AdminPage />
          </RequireAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequireAuth admin={false}>
            <RecoilURLSyncJSON
              storeKey={HOME_PAGE_STORE_KEY}
              location={{ part: 'queryParams' }}
            >
              <HomePage />
            </RecoilURLSyncJSON>
          </RequireAuth>
        }
      />
      <Route
        path="/curriculum"
        element={
          <RequireAuth admin={false}>
            <CurriculumPage />
          </RequireAuth>
        }
      />
      <Route
        path="/my-plan/year-plan"
        element={
          <RequireAuth admin={false}>
            <RecoilURLSyncJSON
              storeKey={YEAR_PLAN_PAGE_STORE_KEY}
              location={{ part: 'queryParams' }}
            >
              <YearPlanPage />
            </RecoilURLSyncJSON>
          </RequireAuth>
        }
      />
      <Route
        path="/progress"
        element={
          <RequireAuth admin={false}>
            <ProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/student/progress"
        element={
          <RequireAuth admin={false}>
            <StudentProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/groups"
        element={
          <RequireAuth admin={false}>
            <GroupsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/progress/subject/:subject/:grade"
        element={
          <RequireAuth admin={false}>
            <SubjectProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/progress/transversal/:transversalCode/:transversal"
        element={
          <RequireAuth admin={false}>
            <TransversalProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/student/progress/transversal/:transversalCode/:transversal"
        element={
          <RequireAuth admin={false}>
            <StudentTransversalProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/curriculum/subjects"
        element={
          <RequireAuth admin={false}>
            <RecoilURLSyncJSON
              storeKey={CURRICULUM_PAGE_STORE_KEY}
              location={{ part: 'queryParams' }}
            >
              <CurriculumSubjectsPage />
            </RecoilURLSyncJSON>
          </RequireAuth>
        }
      />
      <Route
        path="/logout"
        element={
          <Navigate
            to={
              process.env.REACT_APP_BASE_URL
                ? `${process.env.REACT_APP_BASE_URL}/login`
                : ''
            }
          />
        }
      />
      <Route
        path="/login"
        element={
          <RedirectToHomeIfLoggedIn>
            <NavigateToLogin />
          </RedirectToHomeIfLoggedIn>
        }
      />
      <Route
        path="/delete-my-data"
        element={
          <RequireAuth admin={false}>
            <DeleteData />
          </RequireAuth>
        }
      />
    </Routes>
  );
};
