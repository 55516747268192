import { Grid, Box, Stack } from '@mui/material';
import React, { ReactNode, useEffect, useRef } from 'react';
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  format,
  isSameDay,
  getHours,
} from 'date-fns';
import styled from 'styled-components';
import { BORDER_2, MINUTE_HEIGHT_IN_PX, SCHEDULE_HEADER_PX } from './constants';
import { ScheduleSubject } from './ScheduleSubject';
import { VisualHourDividers } from './VisualHourDividers';
import { EnhancedSubjectTiming } from '../../api/timetablesapi';
import { DETAIL_COLOR } from '../../theme';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { IconActionButton } from '../../components/IconActionButton';
import {
  SCHEDULE_COLUMN_HEADER as HEADER_Z_INDEX,
  SCHEDULE_CURRENT_TIME_INDICATOR as INDICATOR_Z_INDEX,
} from '../../utils/z-index';

const LESSON_GAP = 5; // applied at the end of lesson

export const overLappingTimes = (
  time: EnhancedSubjectTiming,
  allTimes: EnhancedSubjectTiming[]
): EnhancedSubjectTiming[] =>
  allTimes.filter((t) =>
    areIntervalsOverlapping(
      { start: time.startAtDate, end: time.endAtDate },
      { start: t.startAtDate, end: t.endAtDate }
    )
  );

const DayHeader = styled.div`
  height: ${SCHEDULE_HEADER_PX}px;
  width: 100%;
  padding: 1em;
  font-size: 14px;
  text-align: center;
  font: RobotoMedium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background-color: #154657;

  .date {
    font-size: 2em;
    font-weight: 900;
  }
`;

const CurrentDayHeader = styled(DayHeader)`
  font-weight: 900;
  background-color: ${DETAIL_COLOR};
  font: RobotoBold;
`;

const calculateTopMargin = (startTime: Date, dayStart: Date): string => {
  const minutes = differenceInMinutes(startTime, dayStart);
  const topMargin = SCHEDULE_HEADER_PX + minutes * MINUTE_HEIGHT_IN_PX;
  return `${topMargin}px`;
};

const calculateHeight = (startTime: Date, endTime: Date): number => {
  const minutes = differenceInMinutes(endTime, startTime);
  const height = minutes * MINUTE_HEIGHT_IN_PX - LESSON_GAP;
  return height;
};

const CurrentTimeIndicator = (props: { top: string }) => {
  const scrollRef = useRef(null);
  useEffect(
    () =>
      (scrollRef as any)?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    []
  );
  return (
    <div
      ref={scrollRef}
      style={{
        position: 'absolute',
        top: `calc(${props.top} - 10px)`,
        height: '20px',
        zIndex: INDICATOR_Z_INDEX,
        width: '100%',
      }}
    >
      <Box
        style={{
          backgroundColor: DETAIL_COLOR,
          height: '20px',
          width: '20px',
          borderRadius: '50%',
          float: 'left',
        }}
      />
      <Box
        style={{
          backgroundColor: DETAIL_COLOR,
          height: '2px',
          width: '100%',
          margin: '8px 0',
        }}
      />
    </div>
  );
};

const showCurrentTimeIndicator = (
  isCurrentDay: boolean,
  dayStart: Date,
  hoursInDay: number,
  currentTime: Date
): boolean => {
  if (!isCurrentDay) {
    return false;
  }
  const currentHour = getHours(currentTime);
  const startHour = getHours(dayStart);
  const endHour = startHour + hoursInDay;
  return currentHour >= startHour && currentHour < endHour;
};

const ColumnHeader = (props: {
  weekday: string;
  date: Date;
  isCurrentDay: boolean;
  buttonLeft?: ReactNode;
  buttonRight?: ReactNode;
}) => {
  const { isTablet } = useBreakpoints();

  const Container = props.isCurrentDay ? CurrentDayHeader : DayHeader;

  let sizeLeft = 'calc(100%/3)';
  let sizeRight = 'calc(100%/3)';
  let sizeMiddle = 'calc(100%/3)';

  if (isTablet) {
    sizeLeft = props.buttonLeft ? '50%' : '0%';
    sizeRight = props.buttonRight ? '50%' : '0%';
    sizeMiddle = props.buttonLeft || props.buttonRight ? '50%' : '100%';
  }

  return (
    <Container>
      <Stack direction="row">
        <Box
          style={{
            width: sizeLeft,
          }}
        >
          {props.buttonLeft}
        </Box>
        <Box style={{ width: sizeMiddle }}>
          <div>{props.weekday}</div>
          <div className="date">{format(props.date, 'd')}</div>
        </Box>
        <Box
          style={{
            width: sizeRight,
            minWidth: sizeRight,
          }}
        >
          {props.buttonRight}
        </Box>
      </Stack>
    </Container>
  );
};

export const Day = ({
  dayStart,
  hoursInDay,
  currentDate,
  day,
  times: subjectTimings,
  isFirst,
  isLast,
  fullHeight,
  switchToNextWeek,
  switchToPrevWeek,
  activeSubjectTiming,
  setActiveSubjectTiming,
}: {
  dayStart: Date;
  hoursInDay: string[];
  currentDate: Date;
  day: string;
  times: EnhancedSubjectTiming[];
  isFirst: boolean;
  isLast: boolean;
  fullHeight: string;
  switchToNextWeek: () => void;
  switchToPrevWeek: () => void;
  activeSubjectTiming?: EnhancedSubjectTiming;
  setActiveSubjectTiming: (st: string) => void;
}) => {
  const { isPhone } = useBreakpoints();
  const isCurrentDay = isSameDay(currentDate, new Date());

  return (
    <Grid
      item
      xs={12}
      sm
      style={{
        borderRight: !isLast || isPhone ? BORDER_2 : '',
        height: fullHeight,
        position: 'relative',
        backgroundColor: '#f2f3f5',
      }}
    >
      <Grid
        container
        style={{
          position: 'sticky',
          top: 0,
          zIndex: HEADER_Z_INDEX,
        }}
      >
        <ColumnHeader
          weekday={day}
          date={currentDate}
          isCurrentDay={isCurrentDay}
          buttonLeft={
            isFirst ? (
              <IconActionButton
                color="white"
                size="large"
                name="chevron-left"
                onClick={() => switchToPrevWeek()}
              />
            ) : null
          }
          buttonRight={
            isLast ? (
              <IconActionButton
                color="white"
                size="large"
                name="chevron-right"
                onClick={() => switchToNextWeek()}
              />
            ) : null
          }
        />
      </Grid>
      <VisualHourDividers
        count={hoursInDay.length}
        first={calculateTopMargin(dayStart, dayStart)}
        interval={MINUTE_HEIGHT_IN_PX * 60}
      />
      {showCurrentTimeIndicator(
        isCurrentDay,
        dayStart,
        hoursInDay.length,
        new Date()
      ) && (
        <CurrentTimeIndicator top={calculateTopMargin(new Date(), dayStart)} />
      )}
      {subjectTimings
        .filter((subject) => isSameDay(currentDate, subject.startAtDate))
        .map((subject, index) => {
          const overLapping = overLappingTimes(subject, subjectTimings);
          return (
            <ScheduleSubject
              // eslint-disable-next-line react/no-array-index-key
              key={currentDate.toString() + subject.startAtDate + index}
              topMargin={calculateTopMargin(subject.startAtDate, dayStart)}
              height={calculateHeight(subject.startAtDate, subject.endAtDate)}
              subjectTiming={subject}
              activeSubjectTiming={activeSubjectTiming}
              setActiveSubjectTiming={setActiveSubjectTiming}
              widthPercentage={
                overLapping.length === 1 ? 100 : 100 / overLapping.length
              }
              leftPercentage={
                overLapping.length === 1
                  ? undefined
                  : (overLapping.indexOf(subject) / overLapping.length) * 100
              }
            />
          );
        })}
    </Grid>
  );
};
