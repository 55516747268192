import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SCHEDULE_API_KEY } from '../api/scheduleapi';
import { TOPICS_QUERY_KEY, putTopic, postTopic } from '../api/topicapi';
import { CONTENT_PROGRESS_API_QUERY_KEY } from '../api/contentprogressapi';

export const useTopicMutation = (params: {
  usePost?: boolean;
  groupId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const successHandler = useCallback(
    async (data: any) => {
      queryClient.setQueryData(
        [TOPICS_QUERY_KEY, { groupId: params.groupId }],
        (oldData: any) => [
          ...oldData.filter((oD: any) => oD.topicId !== data.topic.topicId),
          data.topic,
        ]
      );
      await queryClient.invalidateQueries({
        queryKey: [SCHEDULE_API_KEY],
      });
      await queryClient.invalidateQueries({
        queryKey: [
          CONTENT_PROGRESS_API_QUERY_KEY,
          {
            groupId: params.groupId,
          },
        ],
      });
      if (params.onSuccess) {
        params.onSuccess();
      }
    },
    [params.groupId, params.onSuccess, queryClient]
  );

  const errorHandler = useCallback(() => {
    if (params.onError) {
      params.onError();
    }
  }, [params.onError]);

  const topicMutation = useMutation(params.usePost ? postTopic : putTopic, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

  return { topicMutation };
};
