import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { IntlProvider, useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { clarity } from 'react-microsoft-clarity';
import './App.css';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Stack } from '@mui/material';
import { getUserLang } from '../api/usersapi';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { RootRoute } from '../app-base/RootRoute';
import { locale } from './state/state';
import { LocaleEnum, getMessages } from '../utils/locale';

import Header from '../app-base/Header';
import { MainNav } from '../app-base/MainNav';
import { HEADER } from '../theme';
import { QUERY_CONFIG } from '../utils/queryconfig';

const stringToLocaleEnum = (lc: string | unknown): LocaleEnum =>
  (lc as string).toLowerCase() as LocaleEnum;

const Metadata = () => {
  const intl = useIntl();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="" />
      <title>{intl.formatMessage({ id: 'application.title' })}</title>
      <link rel="canonical" href={process.env.REACT_APP_BASE_URL} />
    </Helmet>
  );
};

export const App = () => {
  const [localeType, setLocale] = useRecoilState(locale);
  const { useHamburgerNavigation, isPhone } = useBreakpoints();
  const userLang = useQuery({
    queryKey: ['userlang'],
    queryFn: () => getUserLang(),
    onSuccess: ({ lang }: { lang?: string }) => {
      if (lang && Object.values(LocaleEnum).some((val) => val === lang)) {
        setLocale(stringToLocaleEnum(lang));
      }
    },
    ...QUERY_CONFIG,
  });

  if (process.env.REACT_APP_BASE_URL === 'https://dev.polarpartners.fi') {
    clarity.init('gqyz8wckcw');
  }
  if (process.env.REACT_APP_BASE_URL === 'https://polarhub.fi') {
    clarity.init('gqyxi3m8un');
  }
  return (
    <IntlProvider locale="fi" key="fi" messages={getMessages(localeType)}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{} as any}
        maxSnack={3}
      >
        <ConfirmProvider>
          <DndProvider backend={HTML5Backend}>
            <Metadata />
            <Stack
              direction="row"
              style={{
                minWidth: 0,
                width: '100%',
                height: '100dvh',
                position: 'relative',
              }}
            >
              {!useHamburgerNavigation && <MainNav />}
              <Stack
                direction="column"
                alignItems="stretch"
                flexGrow={1}
                style={{ minWidth: 0 }}
              >
                <Header />
                <Box
                  style={{ overflowY: 'auto', marginTop: isPhone ? HEADER : 0 }}
                >
                  <RootRoute />
                </Box>
              </Stack>
            </Stack>
          </DndProvider>
        </ConfirmProvider>
      </SnackbarProvider>
    </IntlProvider>
  );
};
