/* eslint-disable @typescript-eslint/indent */
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import {
  set,
  format,
  addDays,
  isWithinInterval,
  parseISO,
  subDays,
  eachHourOfInterval,
  getHours,
  getDay,
} from 'date-fns';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import {
  getSchedule,
  getStudentSchedule,
  ScheduleResponse,
  SCHEDULE_API_KEY,
} from '../../api/scheduleapi';
import { EnhancedSubjectTiming } from '../../api/timetablesapi';
import { Topic } from '../../api/topicapi';
import { useAuth } from '../../utils/auth/useAuth';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import {
  scheduleGroupFilter,
  scheduleSelectedWeek,
  scheduleShowFilters,
  scheduleSubjectFilter,
  scheduleViewClass,
} from '../../App/state/home.state';
import { MINUTE_HEIGHT_IN_PX, SCHEDULE_HEADER_PX } from './constants';
import { Days } from './Days';
import { VisualHoursColumns } from './VisualHoursColumns';
import { getGroups, Group } from '../../api/groupapi';
import { ScheduleFilterSelection } from './ScheduleFilterSelection';
import { PanelOrDrawer } from '../../components/PanelOrDrawer';
import { LessonView } from './LessonView';
import { LARGE_SPACING, XXL_SPACING, XXXL_SPACING } from '../../theme';
import { CenterLoader } from '../../components/CenterLoader';
import { useSubjectColor } from '../../hooks/useSubjectColor';
import { PanelMobile } from '../../components/PanelMobile';
import { getWeekFromDay } from '../../utils/weekhelpers';
import { SubjectColorBox } from '../../components/SubjectColorBox';

const isWeekendDay = (date: Date): boolean => {
  const weekDay = getDay(date); // 0 = Sunday, 1 = Monday...
  return weekDay === 0 || weekDay === 6;
};

const SubjectDrawerTeaser = ({
  selectedSubjectTiming,
}: {
  selectedSubjectTiming: EnhancedSubjectTiming;
}) => {
  const { subjectColor } = useSubjectColor(selectedSubjectTiming.subject);
  return (
    <SubjectColorBox subjectColor={subjectColor} style={{ height: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 4 }}
        height="100%"
      >
        <Typography variant="h6" fontWeight="bold">
          {selectedSubjectTiming.subject}
        </Typography>
        <Box>
          {format(selectedSubjectTiming.startAtDate, 'HH:mm')} -{' '}
          {format(selectedSubjectTiming.endAtDate, 'HH:mm')}
        </Box>
      </Stack>
    </SubjectColorBox>
  );
};

export const Schedule = () => {
  const { useNarrowUiElements, useStickyHeaders } = useBreakpoints();
  const intl = useIntl();
  const { user } = useAuth();
  const groupsResponse = useQuery({
    queryKey: ['groupapi'],
    queryFn: getGroups,
    enabled: user?.role === 'TEACHER',
    ...QUERY_CONFIG,
  });
  // @ts-ignore
  const groups: Group[] = groupsResponse?.data?.length
    ? (groupsResponse.data as unknown as Group[])
    : [];
  const [week, setWeek] = useRecoilState(scheduleSelectedWeek);
  const [showFilters, setShowFilters] = useRecoilState(scheduleShowFilters);
  const [groupFilter, setGroupFilter] = useRecoilState(scheduleGroupFilter);
  const [subjectFilter, setSubjectFilter] = useRecoilState(
    scheduleSubjectFilter
  );
  const [selectedSubjectTimingId, setSelectedSubjectTimingId] =
    useRecoilState(scheduleViewClass);
  const scheduleResult = useQuery(
    [SCHEDULE_API_KEY],
    () => (user?.role === 'STUDENT' ? getStudentSchedule() : getSchedule()),
    {
      ...QUERY_CONFIG,
    }
  );
  if (!scheduleResult.data) {
    return <CenterLoader />;
  }

  const scheduleResponse: ScheduleResponse = scheduleResult.data ?? [];
  const topics: Topic[] = scheduleResponse.flatMap((gr) => gr.topics) ?? [];
  const enhancedSubjectTimings: EnhancedSubjectTiming[] =
    scheduleResponse
      .flatMap((gr) => gr.timetables)
      .flatMap((tt) => {
        const groupName = groups?.find((g) => g.groupId === tt.groupId)?.name;

        return tt.times.map((time) => ({
          ...time,
          startAtDate: parseISO(time.startAt),
          startAt: parseISO(time.startAt).toISOString(),
          endAtDate: parseISO(time.endAt),
          endAt: parseISO(time.endAt).toISOString(),
          timetableId: tt.timetableId!!,
          groupName,
          groupId: tt.groupId,
          topic: topics.filter(
            (topic) =>
              topic.year === time.schoolYear &&
              topic.lessonNumber === time.lessonNumber &&
              topic.subjectId === time.subject &&
              topic.gradeId === time.grade.toString()
          )[0],
        }));
      }) ?? [];

  const subjectTimings: EnhancedSubjectTiming[] = enhancedSubjectTimings.filter(
    (subjectTiming) =>
      isWithinInterval(subjectTiming.startAtDate, {
        start: week.startAt,
        end: week.endAt,
      })
  );
  const selectedSubjectTiming = subjectTimings.find(
    ({ subjectTimingId }) =>
      selectedSubjectTimingId &&
      subjectTimingId &&
      selectedSubjectTimingId === subjectTimingId
  );
  const weekend = subjectTimings.some(
    (st) => isWeekendDay(st.startAtDate) || isWeekendDay(st.endAtDate)
  );

  const START_HOUR = Math.min(
    ...subjectTimings.map((subjectTiming) =>
      getHours(subjectTiming.startAtDate)
    ),
    8
  );
  const END_HOUR = Math.max(
    ...subjectTimings.map((subjectTiming) => getHours(subjectTiming.endAtDate)),
    16
  );

  const hoursInDay = eachHourOfInterval({
    start: set(new Date(), { hours: START_HOUR }),
    end: set(new Date(), { hours: END_HOUR }),
  }).map((d) => format(d, 'HH:mm'));

  const initializedScheduleFilterSelection = (
    <ScheduleFilterSelection
      activeGroups={
        subjectFilter
          ? [
              ...new Set(
                enhancedSubjectTimings
                  .filter((ast) => subjectFilter.includes(ast.subject))
                  .map((ast) => ast.groupId)
              ),
            ]
          : []
      }
      activeSubjects={
        groupFilter
          ? [
              ...new Set(
                enhancedSubjectTimings
                  .filter(
                    (ast) =>
                      groupFilter.length === 0 ||
                      groupFilter.includes(ast.groupId!!)
                  )
                  .map((ast) => ast.subject)
              ),
            ]
          : []
      }
      groups={[
        ...new Set(
          groups?.map((g) => ({
            groupId: g.groupId!!,
            name: g.name,
          }))
        ),
      ]}
      subjects={[...new Set(enhancedSubjectTimings.map((ast) => ast.subject))]}
      setGroupFilter={(groupIds: string[]) => setGroupFilter(groupIds)}
      setSubjectFilter={(subjectIds: string[]) => setSubjectFilter(subjectIds)}
      filterGroup={groupFilter}
      filterSubject={subjectFilter}
    />
  );

  const fullHeight =
    hoursInDay.length * 60 * MINUTE_HEIGHT_IN_PX + SCHEDULE_HEADER_PX;

  const switchToPrevWeek = () => {
    setWeek(getWeekFromDay(subDays(week.startAt, 7)));
    window.scrollTo(0, 0);
  };

  const switchToNextWeek = () => {
    setWeek(getWeekFromDay(addDays(week.startAt, 7)));
    window.scrollTo(0, 0);
  };

  return (
    <Stack direction="row" style={{ height: '100%', maxHeight: '100%' }}>
      <PanelMobile
        title={intl.formatMessage({ id: 'panelMobile.groupSubjectTitle' })}
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        onOpen={() => setShowFilters(true)}
      >
        {initializedScheduleFilterSelection}
      </PanelMobile>
      <Stack
        direction="column"
        alignItems="stretch"
        flexGrow={1}
        spacing={XXL_SPACING}
        sx={{ px: useNarrowUiElements ? 0 : 0 }}
        style={{ height: '100%', maxHeight: '100%', overflowY: 'auto' }}
      >
        <Grid container>
          <Grid item xs={3} sm={2} lg={1} style={{ height: `${fullHeight}px` }}>
            <VisualHoursColumns
              hoursInDay={hoursInDay}
              week={week}
              setWeek={setWeek}
              weekend={weekend}
            />
          </Grid>
          <Grid item xs sm>
            <Grid container>
              <Days
                week={week}
                startHour={START_HOUR}
                hoursInDay={hoursInDay}
                times={subjectTimings
                  .filter((subjectTiming) =>
                    groupFilter.length > 0 && subjectTiming.groupId
                      ? groupFilter.includes(subjectTiming.groupId)
                      : true
                  )
                  .filter(
                    (subjectTiming) =>
                      subjectFilter.includes(subjectTiming.subject) ||
                      subjectFilter.length === 0
                  )}
                showWeekend={weekend}
                fullHeight={`${fullHeight}px`}
                switchToNextWeek={switchToNextWeek}
                switchToPrevWeek={switchToPrevWeek}
                activeSubjectTiming={selectedSubjectTiming}
                setActiveSubjectTiming={setSelectedSubjectTimingId}
              />
            </Grid>
          </Grid>
        </Grid>
        {useStickyHeaders && (
          <Button
            sx={{ mt: LARGE_SPACING, mb: XXXL_SPACING + XXXL_SPACING }}
            fullWidth
            onClick={switchToNextWeek}
            style={{
              border: '1px solid #0000001F',
            }}
          >
            {intl.formatMessage({ id: 'schedule.buttons.nextWeek' })}
          </Button>
        )}
      </Stack>
      {selectedSubjectTiming && (
        <PanelOrDrawer
          panelAlignment="right"
          closeCallback={() => setSelectedSubjectTimingId(undefined)}
          closedDrawerTeaser={
            selectedSubjectTiming && (
              <SubjectDrawerTeaser
                selectedSubjectTiming={selectedSubjectTiming}
              />
            )
          }
        >
          <LessonView
            isStudent={user?.role !== 'TEACHER'}
            subjectTiming={selectedSubjectTiming}
          />
        </PanelOrDrawer>
      )}
    </Stack>
  );
};
