/* eslint-disable no-nested-ternary */
import { Box, Button, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, SetterOrUpdater } from 'recoil';
import { useClickAway } from 'ahooks';
import { SubjectTiming, TimeTable } from '../../../api/timetablesapi';
import {
  postTopic,
  putTopic,
  Topic,
  TOPICS_QUERY_KEY,
} from '../../../api/topicapi';
import { SCHEDULE_API_KEY } from '../../../api/scheduleapi';
import {
  ContentViewState,
  contentContentDialogOpen,
  contentLoadingState,
  contentState,
  isContentViewState,
  yearPlanDialogActiveTab,
} from '../../../App/state/year-plan.state';
import { CenterLoader } from '../../../components/CenterLoader';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { SubjectColorDefinition } from '../../../hooks/useSubjectColor';
import {
  BOLD_FONT_WEIGHT,
  SELECTED_BACKGROUND,
  SMALL_SPACING,
  THEME_DISABLED_COLOR,
  THEME_MAIN_TEXT_COLOR,
  THICKER_SELECTED_DETAIL_BORDER,
} from '../../../theme';
import { findBulletpointDescFromSubject } from '../../../utils/ops/findBulletpointDescFromSubjects';
import { SubjectItem } from '../../ops/components/types/types';
import { BulletpointChip } from './BulletpointChip';
import {
  YEAR_PLAN_CARD_BORDER,
  YEAR_PLAN_CARD_HEIGHT,
  YEAR_PLAN_CARD_HEIGHT_IN_HEADER,
  YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED,
  YEAR_PLAN_CARD_SELECTED_WIDTH,
  YEAR_PLAN_CARD_WIDTH,
} from './Commons';
import { createScrollableId } from './utils';
import { YearPlanHeader } from './YearPlanHeader';
import {
  ActivityOrderType,
  ActivityOrderTypes,
} from '../lesson-plan/ActivityOrder';

const ContentYearPlanCardButton = ({
  loading,
  bulletpointDescs,
  onClick,
  cardHeight,
  style = {},
  isSelected = false,
  isInHeader = false,
}: {
  isSelected?: boolean;
  isInHeader?: boolean;
  loading: boolean;
  cardHeight: number;
  bulletpointDescs?: (string | undefined)[] | undefined;
  onClick: () => void;
  style?: Record<string, string | number>;
}) => {
  const intl = useIntl();
  return (
    <Button
      onClick={onClick}
      variant="text"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height:
          isSelected && isInHeader
            ? `${YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED / 2}px`
            : `${cardHeight / 2}px`,
        margin: 0,
        borderRadius: '0%',
        textTransform: 'none',
        ...style,
      }}
    >
      {loading && <CenterLoader />}
      {!loading && bulletpointDescs?.length === 0 && (
        <>
          <FeatherIcon
            color={THEME_MAIN_TEXT_COLOR}
            size={IconSize['SMALL-MEDIUM']}
            name="plus"
            style={{ display: 'block', marginTop: '-1px', marginRight: '4px' }}
          />
          <Typography
            variant="body2"
            style={{
              display: 'block',
              fontWeight: BOLD_FONT_WEIGHT,
              textTransform: 'uppercase',
            }}
          >
            {intl.formatMessage({ id: 'opsPage.ops.content' })}
          </Typography>
        </>
      )}
      {!loading && bulletpointDescs && bulletpointDescs?.length > 0 && (
        <Box
          style={{
            whiteSpace: 'nowrap',
            overflow: 'auto',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'column',
            height:
              isSelected && isInHeader
                ? `${YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED / 2}px`
                : `${cardHeight / 2}px`,
          }}
          sx={{ p: SMALL_SPACING }}
        >
          {bulletpointDescs
            .filter((bp) => !!bp)
            ?.map((bp) => (
              <BulletpointChip
                smallMode={false}
                key={`bulletpointChipInButton${bp}`}
                bulletpointDesc={bp!!}
                blockMode={true}
              />
            ))}
        </Box>
      )}
    </Button>
  );
};
const YearPlanCardButton = ({
  iconName = 'plus',
  buttonText,
  onClick,
  style = {},
  disabled = false,
  additionalIcon = false,
}: {
  iconName?: string;
  buttonText: string;
  onClick: () => void;
  style?: Record<string, string | number>;
  disabled?: boolean;
  additionalIcon?: boolean;
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    variant="text"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      margin: 0,
      borderRadius: '0%',
      ...style,
    }}
  >
    {additionalIcon && (
      <FeatherIcon
        color="#21A52A"
        size={IconSize['SMALL-MEDIUM']}
        name="check"
        style={{ display: 'block', marginTop: '-1px', marginRight: '4px' }}
      />
    )}
    <FeatherIcon
      color={disabled ? THEME_DISABLED_COLOR : THEME_MAIN_TEXT_COLOR}
      size={IconSize['SMALL-MEDIUM']}
      name={iconName}
      style={{ display: 'block', marginTop: '-1px', marginRight: '4px' }}
    />
    <Typography
      variant="body2"
      style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
    >
      {buttonText}
    </Typography>
  </Button>
);

const TopicNameInput = (props: {
  value: string;
  onChange: (newValue: string) => void;
  onDone: () => void;
}) => {
  const ref = useRef(null);
  const intl = useIntl();
  useClickAway(() => props.onDone(), [ref]);
  return (
    <TextField
      ref={ref}
      size="small"
      fullWidth={true}
      label={intl.formatMessage({ id: 'opsPage.ops.lessonTopic' })}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
};

export const YearPlanCard = ({
  lessonNumber,
  amountOfLessons,
  grade,
  subject,
  year,
  groupId,
  groupName,
  timetables,
  topics,
  refetchTopics,
  subjectColor,
  isInHeader = false,
}: {
  lessonNumber: number;
  amountOfLessons: number;
  grade: string;
  subject: SubjectItem;
  groupId: string;
  groupName: string;
  year: number;
  timetables: TimeTable[];
  topics: Topic[];
  refetchTopics: () => void;
  subjectColor: SubjectColorDefinition;
  isInHeader?: boolean;
}) => {
  const intl = useIntl();
  const [____, setActiveTab] = useRecoilState(yearPlanDialogActiveTab);
  const [__, setContentDialogOpen] = useRecoilState(contentContentDialogOpen);
  const [contentLoading, _] = useRecoilState(contentLoadingState);
  const [contentViewState, setContentViewState] = useRecoilState(contentState);
  const topic: Topic | undefined = useMemo(
    () =>
      topics?.find(
        (t: Topic) =>
          t.year === year &&
          t.lessonNumber === lessonNumber &&
          t.gradeId === grade &&
          t.subjectId === subject.code
      ),
    [topics, year, lessonNumber, grade, subject]
  );
  const [topicNameInput, setTopicNameInput] = useState(topic?.name || '');
  const isSelected = useMemo(
    () =>
      isContentViewState(contentViewState) &&
      contentViewState.lessonNumber === lessonNumber &&
      contentViewState.year === year &&
      contentViewState.grade === grade &&
      contentViewState.groupId === groupId &&
      contentViewState.subject === subject.code,
    [contentViewState, lessonNumber, year, grade, groupId, subject]
  );
  const insertMutation = useMutation(postTopic, {});
  const updateMutation = useMutation(putTopic, {});
  const queryClient = useQueryClient();
  const insertTopic = useCallback(
    (name: string) =>
      insertMutation
        .mutateAsync({
          name,
          description: 'not-used-currently',
          subjectId: subject.code,
          gradeId: grade,
          year,
          lessonNumber,
          groupId,
          tasks: [],
          activityOrder: {
            initiation: [],
            practicing: [],
            closing: [],
            homework: [],
          },
        })
        .then((createResponse) => {
          setTopicNameInput(name);
          refetchTopics();
          queryClient.invalidateQueries({
            queryKey: [SCHEDULE_API_KEY],
          });
          return createResponse;
        }),
    [subject, grade]
  );

  const clickContentButton = useCallback(() => {
    const section = document.querySelector(
      `#${createScrollableId(lessonNumber, year, grade, subject.code)}`
    );
    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });

    setContentViewState({
      grade,
      subject: subject.code,
      groupName,
      groupId,
      amountOfLessons,
      lessonNumber,
      year,
    });
  }, [lessonNumber, year, grade, subject, groupName, groupId, amountOfLessons]);

  const subjectTiming: SubjectTiming | undefined = useMemo(
    () =>
      timetables
        ?.flatMap((t: TimeTable) => t.times)
        .find(
          (st: SubjectTiming) =>
            st.schoolYear === year &&
            st.lessonNumber === lessonNumber &&
            st.grade === grade &&
            st.subject === subject.code
        ),
    [timetables, year, lessonNumber, grade, subject]
  );

  const subjectTimingStartAtDate = useMemo(
    () =>
      subjectTiming?.startAt ? parseISO(subjectTiming.startAt) : undefined,
    [subjectTiming]
  );
  const subjectTimingEndAtDate = useMemo(
    () => (subjectTiming?.endAt ? parseISO(subjectTiming.endAt) : undefined),
    [subjectTiming]
  );

  const TIME_HEADING_HEIGHT = 50;

  const cardHeight = useMemo(
    () =>
      isInHeader ? YEAR_PLAN_CARD_HEIGHT_IN_HEADER : YEAR_PLAN_CARD_HEIGHT,
    [isInHeader]
  );

  const scrollId = useMemo(
    () => createScrollableId(lessonNumber, year, grade, subject.code),
    [lessonNumber, year, grade, subject]
  );

  const bulletpointDescs = useMemo(
    () =>
      (topic?.tasks?.map((t) => t.bulletpointId) ?? [])
        ?.map((bulletpointId) =>
          findBulletpointDescFromSubject(bulletpointId, grade, subject)
        )
        .filter((bp) => bp !== undefined),
    [topic, grade, subject]
  );

  const onTopicNameChange = useCallback(() => {
    if (!topicNameInput || topicNameInput.length === 0) {
      return;
    }
    if (!topic?.topicId) {
      insertTopic(topicNameInput)
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: 'snackBar.topicName.created' }),
            {
              variant: 'success',
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: 'snackBar.topicName.failed' }),
            {
              variant: 'error',
            }
          );
        });
      return;
    }
    if (topicNameInput === topic.name) {
      return;
    }
    updateMutation
      .mutateAsync({
        ...topic,
        name: topicNameInput,
      })
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({ id: 'snackBar.topicName.updated' }),
          {
            variant: 'success',
          }
        );
        queryClient.invalidateQueries({
          queryKey: [
            TOPICS_QUERY_KEY,
            {
              groupId,
            },
          ],
        });
        queryClient.invalidateQueries({
          queryKey: [SCHEDULE_API_KEY],
        });
      })
      .catch(() =>
        enqueueSnackbar(
          intl.formatMessage({ id: 'snackBar.topicName.failed' }),
          {
            variant: 'error',
          }
        )
      );
  }, [topicNameInput, topic, groupId]);
  const clickContentYearPlanCardButton = () => {
    setActiveTab('Content');
    setContentViewState({});
    clickContentButton();
  };

  const clickYearPlanCardButton = () => {
    if (topic) {
      setContentViewState(
        (curValue) =>
          ({
            ...curValue,
            topic,
          } as any)
      );
      setActiveTab('Lesson');
      setContentDialogOpen(true);
      clickContentButton();
    } else {
      const topicName =
        topicNameInput && topicNameInput.length > 0
          ? topicNameInput
          : intl.formatMessage({ id: 'opsPage.ops.lessonTopic' });
      insertTopic(topicName).then((createResponse) => {
        setContentViewState(
          (existingContentViewState) =>
            ({
              ...existingContentViewState,
              topic: createResponse.topic,
            } as any)
        );
        setActiveTab('Lesson');
        clickContentButton();
      });
    }
  };
  return (
    <>
      <Box
        style={{
          height: TIME_HEADING_HEIGHT,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {subjectTimingStartAtDate && subjectTimingEndAtDate && (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="body2"
              style={{
                display: 'block',
                textAlign: 'center',
                fontWeight: BOLD_FONT_WEIGHT,
              }}
            >
              {format(subjectTimingStartAtDate, 'E dd.LL.')}
            </Typography>
            <Typography
              variant="body2"
              style={{ display: 'block', textAlign: 'center' }}
            >
              {format(subjectTimingStartAtDate, 'HH:mm')} -{' '}
              {format(subjectTimingEndAtDate, 'HH:mm')}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        key={`yearplanlessoncard-${lessonNumber}`}
        style={{
          backgroundColor: lessonNumber % 2 === 0 ? '#F2F3F5' : '#FFFFFF',
          borderRadius: ' 0 0 12px 12px',
          boxSizing: 'border-box',
        }}
      >
        <Box id={scrollId}>
          <YearPlanHeader
            lessonNumber={lessonNumber}
            amountOfLessons={amountOfLessons}
            subjectColor={subjectColor}
          />
          <Box
            style={{
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: `${
                isSelected
                  ? YEAR_PLAN_CARD_SELECTED_WIDTH
                  : YEAR_PLAN_CARD_WIDTH
              }px`,
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <TopicNameInput
              value={topicNameInput}
              onChange={setTopicNameInput}
              onDone={onTopicNameChange}
            />
          </Box>
          <ContentYearPlanCardButton
            cardHeight={cardHeight}
            isSelected={isSelected}
            isInHeader={isInHeader}
            loading={isSelected && contentLoading}
            bulletpointDescs={bulletpointDescs}
            onClick={clickContentYearPlanCardButton}
            style={{
              width: isSelected
                ? `${YEAR_PLAN_CARD_SELECTED_WIDTH}px`
                : `${YEAR_PLAN_CARD_WIDTH}px`,
              outline: isSelected ? THICKER_SELECTED_DETAIL_BORDER : '',
              borderBottom: isSelected ? '' : YEAR_PLAN_CARD_BORDER,
              borderTop: isSelected ? '' : YEAR_PLAN_CARD_BORDER,
              ...(isSelected && { background: SELECTED_BACKGROUND }),
              transition: 'width 0.5s',
            }}
          />
          <YearPlanCardButton
            onClick={clickYearPlanCardButton}
            buttonText={intl.formatMessage({ id: 'opsPage.ops.lessonPlan' })}
            style={{
              textTransform: 'uppercase',
              width: isSelected
                ? `${YEAR_PLAN_CARD_SELECTED_WIDTH}px`
                : `${YEAR_PLAN_CARD_WIDTH}px`,
              height:
                !isSelected && isInHeader
                  ? `${cardHeight / 4}px`
                  : `${YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED / 4}px`,
              borderTop: YEAR_PLAN_CARD_BORDER,
              transition: 'width 0.5s',
            }}
            iconName={
              topic?.activityOrder &&
              ActivityOrderTypes?.some(
                (key) =>
                  topic!!.activityOrder!![key as ActivityOrderType]!!.length > 0
              )
                ? 'edit-2'
                : 'plus'
            }
            additionalIcon={
              topic?.activityOrder &&
              ActivityOrderTypes?.some(
                (key) =>
                  topic!!.activityOrder!![key as ActivityOrderType]!!.length > 0
              )
            }
          />
        </Box>
      </Box>
    </>
  );
};
