/* eslint-disable @typescript-eslint/indent */
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';

import {
  BOLD_FONT_WEIGHT,
  MEDIUM_SPACING,
  LARGE_SPACING,
  XXL_SPACING,
} from '../../../theme';
import { parseSubjectList } from '../../../utils/ops/utils';
import { ReadObjective } from './view-tab/ReadObjective';
import { FilterCategory } from '../../../components/FilterCategory';
import { PanelMobile } from '../../../components/PanelMobile';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { useOps } from '../../../hooks/useOps';
import { SubjectItem } from '../../ops/components/types/types';
import { PanelContentDivider } from '../../../components/PanelContentDivider';
import { SubjectColorBox } from '../../../components/SubjectColorBox';
import { LabeledValueBig } from '../../../components/HeaderInfo';
import { PanelOrDrawer } from '../../../components/PanelOrDrawer';
import {
  curriculumLinksState,
  curriculumSubjectsPageGrade,
  curriculumSubjectsPageLeftPanelOpen,
  curriculumSubjectsPageSelectedContentIds,
  curriculumSubjectsPageSelectedObjectiveIds,
  curriculumSubjectsPageSubject,
} from '../../../App/state/curriculum.state';
import { getSubjectsByGrade } from '../../../utils/ops/getSubjectsByGrade';
import { LinksPanelOrDrawer } from '../LinksPanelOrDrawer';

const Filters = ({
  grade,
  subject,
  subjects,
  setGrade,
  setSubject,
}: {
  grade: number;
  subject: string;
  subjects: { code: string; title: string }[];
  setGrade: (grade: number) => void;
  setSubject: (subject: string) => void;
}) => {
  const { subjectColors } = useSubjectColor(subjects.map((s) => s.code));
  const intl = useIntl();
  return (
    <Box>
      <FilterCategory
        title={intl.formatMessage({ id: 'opsPage.ops.grades' })}
        options={Array.from({ length: 9 }, (_, i) => i + 1).map((g) => ({
          id: String(g),
          label: String(g),
          active: grade === g,
        }))}
        onToggle={(id) => {
          setGrade(Number(id));
        }}
        type="radio"
      />
      <PanelContentDivider />
      <FilterCategory
        title={intl.formatMessage({ id: 'opsPage.ops.subjects' })}
        options={subjects.map((s) => ({
          id: s.code,
          label: s.title,
          accent: subjectColors[s.code].primaryColor,
          active: subject === s.code,
        }))}
        onToggle={(id) => {
          setSubject(id);
        }}
        type="radio"
      />
    </Box>
  );
};

export const CurriculumSubjectsPage = () => {
  const intl = useIntl();
  const { ops } = useOps();
  const [panelOpen, setPanelOpen] = useRecoilState(
    curriculumSubjectsPageLeftPanelOpen
  );
  const [grade, setGrade] = useRecoilState(curriculumSubjectsPageGrade);
  const [subject, setSubject] = useRecoilState(curriculumSubjectsPageSubject);
  const [__, setSelectedContentIds] = useRecoilState(
    curriculumSubjectsPageSelectedContentIds
  );
  const [___, setSelectedObjectiveIds] = useRecoilState(
    curriculumSubjectsPageSelectedObjectiveIds
  );
  const [linksState, setLinksState] = useRecoilState(curriculumLinksState);
  const [rightPanelOpenState, setRightPanelOpenState] = useState(true);
  const { subjectColor } = useSubjectColor(subject);
  React.useEffect(() => {
    if (linksState) {
      setRightPanelOpenState(true);
    }
  }, [linksState]);
  if (!ops) {
    return <CircularProgress />;
  }
  const rawSubjects = parseSubjectList(ops, { onlyFavorites: false }, []);
  const filters = (
    <Filters
      grade={grade}
      subject={subject}
      subjects={[...new Set(rawSubjects.values())].map((s: SubjectItem) => ({
        code: s.code,
        title: s.title,
      }))}
      setGrade={(gr: number) => {
        setSelectedContentIds([]);
        setSelectedObjectiveIds([]);
        setLinksState(undefined);
        setGrade(gr);
      }}
      setSubject={(s: string) => {
        setSelectedContentIds([]);
        setSelectedObjectiveIds([]);
        setLinksState(undefined);
        setSubject(s);
      }}
    />
  );

  const subjectItem = getSubjectsByGrade(rawSubjects, grade).find(
    (it) => it.code === subject
  );

  return (
    <Stack
      direction="row"
      style={{
        minHeight: `100%`,
        maxHeight: '100%',
      }}
    >
      <PanelMobile
        title={intl.formatMessage({ id: 'panelMobile.gradeSubjectTitle' })}
        isOpen={panelOpen}
        onClose={() => setPanelOpen(false)}
        onOpen={() => setPanelOpen(true)}
      >
        {filters}
      </PanelMobile>

      <Stack
        direction="column"
        flexGrow={1}
        style={{ minWidth: 0, overflowY: 'auto' }}
      >
        {!grade ||
          (!subject && (
            <Box sx={{ m: 'auto' }} style={{ textAlign: 'center' }}>
              <Typography
                variant="h4"
                sx={{ m: MEDIUM_SPACING }}
                style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
              >
                {intl
                  .formatMessage({ id: 'curriculumsubjectspage.noSubject' })
                  .toUpperCase()}
              </Typography>
              <Box>
                <FeatherIcon
                  size={IconSize['EXTRA-EXTRA-LARGE']}
                  name="clipboard"
                  color="white"
                  style={{
                    strokeWidth: '1',
                    fill: '#245F73',
                    marginLeft: '-10%',
                    position: 'relative',
                    opacity: '0.45',
                  }}
                />
              </Box>
              <Box>
                <FeatherIcon
                  size={IconSize['EXTRA-EXTRA-LARGE']}
                  name="search"
                  color="#245F73"
                  style={{
                    strokeWidth: '1',
                    fill: 'white',
                    position: 'relative',
                    marginTop: '-20%',
                    marginLeft: '10%',
                  }}
                />
              </Box>

              <Typography
                sx={{ m: MEDIUM_SPACING }}
                variant="h6"
                color="text.secondary"
                style={{ display: 'block' }}
              >
                {intl.formatMessage({
                  id: 'curriculumsubjectspage.noSubjectSelect',
                })}
              </Typography>
            </Box>
          ))}
        {!!grade && !!subject && (
          <Stack>
            <SubjectColorBox subjectColor={subjectColor}>
              <Box sx={{ mx: 20, my: 10 }}>
                <LabeledValueBig label={`${grade}. Grade`} value={subject} />
              </Box>
            </SubjectColorBox>
            <Box sx={{ mt: XXL_SPACING, mx: LARGE_SPACING }}>
              <ScrollContainer hideScrollbars={false}>
                <Box style={{ width: 'fit-content' }}>
                  <ReadObjective subjectCode={subject} grade={grade} />
                </Box>
              </ScrollContainer>
            </Box>
          </Stack>
        )}
      </Stack>
      {grade && subject && linksState && subjectItem && (
        <PanelOrDrawer
          panelAlignment="right"
          showPanelControls={true}
          openCallback={() => setRightPanelOpenState(true)}
          closeCallback={() => setRightPanelOpenState(false)}
          forceOpen={rightPanelOpenState}
        >
          {rightPanelOpenState && (
            <LinksPanelOrDrawer
              grade={grade.toString()}
              subjectCode={subject}
              subjectItem={subjectItem}
              bulletpointId={linksState}
            />
          )}
        </PanelOrDrawer>
      )}
    </Stack>
  );
};
