import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { BOLD_FONT_WEIGHT, THEME_MAIN_COLOR } from '../../../theme';
import { SubjectItem } from '../../ops/components/types/types';
import { DottedLine, YEAR_PLAN_CARD_WIDTH } from './Commons';
import { IconActionButton } from '../../../components/IconActionButton';
import { YearPlanCard } from './YearPlanCard';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { useTimetables } from '../../../hooks/useTimetables';
import { useTopics } from '../../../hooks/useTopics';
import { useSubjectColor } from '../../../hooks/useSubjectColor';

export const LessonSwitcher = ({
  amountOfLessons,
  lessonNumber,
  previousLesson,
  nextLesson,
  smallMode = false,
  subject,
  groupId,
  groupName,
  grade,
  year,
}: {
  amountOfLessons: number;
  lessonNumber: number;
  previousLesson: (() => void) | null;
  nextLesson: (() => void) | null;
  smallMode?: boolean;
  subject: SubjectItem;
  groupId: string;
  groupName: string;
  grade: string;
  year: number;
}) => {
  const { isDesktop } = useBreakpoints();
  const { timetables } = useTimetables(groupId);
  const { topics, refetch: refetchTopics } = useTopics(groupId);
  const { subjectColor } = useSubjectColor(subject.code);

  const prevButton = previousLesson ? (
    <IconActionButton
      disabled={lessonNumber === 1}
      color={THEME_MAIN_COLOR}
      onClick={previousLesson}
      name="chevron-left"
      size="medium"
    />
  ) : null;

  const nextButton = nextLesson ? (
    <IconActionButton
      disabled={lessonNumber === amountOfLessons}
      color={THEME_MAIN_COLOR}
      onClick={nextLesson}
      name="chevron-right"
      size="medium"
    />
  ) : null;

  const lessonsToShow =
    isDesktop && (nextButton || prevButton) ? [-1, 0, 1] : [0];

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!smallMode && (
        <Stack direction="row" gap="1em">
          <Stack justifyContent="center">{prevButton}</Stack>
          {lessonsToShow
            .filter(
              (val) =>
                val + lessonNumber > 0 && val + lessonNumber <= amountOfLessons
            )
            .map((switcherIndex) => (
              <Box
                key={`lessonSwitcher${switcherIndex}${lessonNumber}${amountOfLessons}`}
                style={{
                  border: '1px solid #8C8C8C',
                  borderRadius: '12px 12px',
                  height: 'fit-content',
                }}
              >
                <YearPlanCard
                  lessonNumber={lessonNumber + switcherIndex}
                  amountOfLessons={amountOfLessons}
                  subject={subject}
                  groupId={groupId}
                  groupName={groupName}
                  grade={grade}
                  year={year}
                  isInHeader={true}
                  timetables={timetables}
                  topics={topics}
                  refetchTopics={refetchTopics}
                  subjectColor={subjectColor}
                />
              </Box>
            ))}
          {lessonNumber === amountOfLessons && (
            <Box style={{ minWidth: `${YEAR_PLAN_CARD_WIDTH * 2}px` }} />
          )}
          {lessonNumber + 1 === amountOfLessons && (
            <Box style={{ minWidth: `${YEAR_PLAN_CARD_WIDTH}px` }} />
          )}
          <Stack justifyContent="center">{nextButton}</Stack>
        </Stack>
      )}
      {smallMode && (
        <Stack direction="row" alignItems="center" style={{ width: '100%' }}>
          {prevButton}
          <Box
            flexGrow={0.5}
            style={{ fontWeight: BOLD_FONT_WEIGHT, textAlign: 'center' }}
          >
            {lessonNumber}/{amountOfLessons}
            <DottedLine />
          </Box>
          {nextButton}
        </Stack>
      )}
    </Box>
  );
};
